import { User } from '@auth0/auth0-react';
import { format, parseISO } from 'date-fns';
import { axiosInstance } from '../../utils/axios';
import {
  EFilterByResultKeys,
  ISortCriteria,
  defaultFilters,
  filterByResultMaps,
  getSortParameters,
} from './CandidatesPageList/data';
export interface ICandidate {
  app_metadata: {
    company_id: number;
    company_name: string;
    requester: string;
    requester_id: number;
    test_id: string;
    test_name: string;
  };
  email: string;
  user_exists: boolean;
}

export interface ISendTestTrailRequestPayload {
  companyName: string;
  requesterName: string;
  requesterEmail: string;
  testId: string;
  testName: string;
  recipientEmail: string;
}

export interface ITestDescriptionCardProps {
  title: string | null;
  description: string | null;
  image: string;
  descriptionLong: string | null;
}
interface CompletionDetails {
  completed_tasks: string[];
  failed_tasks: string[];
}
export interface ITestReportProps {
  ai_summary: string;
  candidate_email: string;
  candidate_name: string;
  completed_tasks: number;
  completion_score: number;
  completion_details: CompletionDetails;
  invitation_date: string;
  linkedin: string;
  source_url: string;
  status: string;
  submission_date: string;
  test_name: string;
  test_description: string;
  time_taken: string;
  total_tasks: number;
}

export interface IGenPublicRecordingProps {
  trial_id: number;
  test_id: number;
  started_at: string;
  submitted_at: string;
  userhash: string;
}

export const createUser = async (
  accessToken: string,
  {
    email,
    name,
    appMetadata,
    userMetadata,
  }: {
    email: string;
    name: string;
    appMetadata: Record<string, unknown>;
    userMetadata: Record<string, unknown>;
  }
): Promise<User> => {
  const { data } = await axiosInstance.post(
    '/api/admin/users',
    {
      email,
      name,
      app_metadata: appMetadata,
      user_metadata: userMetadata,
      password: crypto.randomUUID(),
      verify_email: false,
      connection: 'Username-Password-Authentication',
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};

export const addAnotherTestForUser = async (
  accessToken: string,
  {
    userhash,
    test_id,
    company_id,
    requester_id,
    issues_per_test,
    available_issues,
    issues_list,
  }: {
    userhash: string;
    test_id: number;
    company_id: number;
    requester_id: number;
    issues_per_test: number;
    available_issues: number;
    issues_list: number[];
  }
): Promise<User> => {
  const { data } = await axiosInstance.postForm(
    '/api/results',
    {
      userhash,
      test_id,
      company_id,
      requester_id,
      issues_per_test,
      available_issues,
      issues_list: issues_list.toString(),
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};

export const changePassword = async (
  accessToken: string,
  {
    email,
    testId,
    ttl = 14,
  }: {
    email: string;
    testId: string;
    ttl?: number;
  }
): Promise<string> => {
  const formData = new FormData();
  formData.append('email', email.toLowerCase());
  formData.append('test_id', testId);
  formData.append('ttl', ttl.toString());
  const { data } = await axiosInstance.post(
    '/api/admin/users/change_password',
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};

export const sendTestTrialInvitation = async (
  accessToken: string,
  {
    companyName,
    requesterName,
    requesterEmail,
    testId,
    testName,
    recipientEmail,
  }: ISendTestTrailRequestPayload
): Promise<string> => {
  const { data } = await axiosInstance.postForm(
    '/api/results/email/send-test-trial-invitation',
    {
      company_name: companyName,
      requester_name: requesterName,
      requester_email: requesterEmail,
      test_id: testId,
      test_name: testName,
      recipient_email: recipientEmail,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};

export const inviteUser = async (
  accessToken: string,
  email: string
): Promise<undefined> => {
  const formData = new FormData();
  formData.append('user_email', email);
  const { data } = await axiosInstance.post(
    '/api/admin/users/invite',
    formData,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data;
};

export const deleteUser = async (
  accessToken: string,
  userID: string
): Promise<undefined> => {
  const { data } = await axiosInstance.delete(`/api/admin/users/${userID}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data;
};

export const downloadReport = async (
  accessToken: string,
  reportData: ITestReportProps
): Promise<any> => {
  const response = await axiosInstance.post(
    '/api/results/reports',
    reportData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
      responseType: 'blob',
    }
  );

  return response;
};

export const generatePublicRecordings = async (
  accessToken: string,
  recordingData: IGenPublicRecordingProps
): Promise<any> => {
  const { data } = await axiosInstance.postForm(
    '/api/results/recordings',
    recordingData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};

export const getTestTimeTaken = (
  time_taken_seconds?: number
): string | null => {
  if (time_taken_seconds === 0) {
    return '00:00:00';
  }

  if (!time_taken_seconds) {
    return null;
  }

  return [
    Math.floor(time_taken_seconds / 3600),
    Math.floor(time_taken_seconds / 60) % 60,
    time_taken_seconds % 60,
  ]
    .join(':')
    .replace(/\b(\d)\b/g, '0$1');
};

export const getDateTime = (isoStr?: string): string => {
  if (isoStr) {
    // we should reconsider updating this format if localization is required
    return format(parseISO(isoStr), 'PP, KK:mm:ss a');
  }
  return 'N/A';
};

export const formatDateToMonthDayYear = (isoStr?: string): string => {
  if (isoStr) {
    return format(parseISO(isoStr), 'MMMM  dd, yyyy');
  }
  return 'N/A';
};

export const getTestTrialsQueryVariables = ({
  limit,
  page,
  search,
  sortCriteria,
  filterByTest,
  filterByResult,
}: {
  limit: number;
  page: number;
  search: string;
  sortCriteria: ISortCriteria[];
  filterByTest: string;
  filterByResult: EFilterByResultKeys;
}): any => {
  const combinedOrderBy = getSortParameters(sortCriteria);

  return {
    variables: {
      filter: {
        ...defaultFilters,
        // this filter hide private test history for company
        // test: {
        //   _or: [
        //     { public: { _eq: true } },
        //     { company_tests: { company_id: { _eq: companyId } } },
        //   ],
        // },
        ...(filterByTest === 'All'
          ? {}
          : { test: { brokee_id: { _eq: parseInt(filterByTest, 10) } } }),
        ...filterByResultMaps[filterByResult],
        ...(!!search.length
          ? {
              candidate: {
                email: { _like: `%${search}%` },
              },
            }
          : {}),
      },
      order_by: combinedOrderBy ?? null,
      limit,
      offset: limit * page,
    },
  };
};

export const getTestTrialsCountQueryVariables = ({
  search,
  sortCriteria,
  filterByTest,
  filterByResult,
}: {
  search: string;
  sortCriteria: ISortCriteria[];
  filterByTest: string;
  filterByResult: EFilterByResultKeys;
}): any => {
  const combinedOrderBy = getSortParameters(sortCriteria);

  return {
    variables: {
      filter: {
        ...defaultFilters,
        ...(filterByTest === 'All'
          ? {}
          : { test: { brokee_id: { _eq: parseInt(filterByTest, 10) } } }),
        ...filterByResultMaps[filterByResult],
        ...(!!search.length
          ? {
              candidate: {
                email: { _like: `%${search}%` },
              },
            }
          : {}),
      },
      order_by: combinedOrderBy ?? null,
    },
  };
};

export interface IHistoryFile {
  download_url: string;
  name: string;
}

export interface IUserCommandHistory {
  count: number;
  files: IHistoryFile[];
}

export const getUserCommandHistory = async (
  accessToken: string,
  userHash: string,
  testId: number,
  testRecord?: boolean,
  startedAt?: string,
  submittedAt?: string
): Promise<IUserCommandHistory> => {
  const { data } = await axiosInstance.get(`/api/results/files`, {
    params: {
      userhash: userHash,
      test_id: testId,
      test_record: testRecord,
      started_at: startedAt,
      submitted_at: submittedAt,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data;
};

export function getTestStatus(passed: boolean | null | undefined) {
  if (passed === true) {
    return 'Passed';
  } else if (passed === false) {
    return 'Failed';
  } else {
    return 'Pending';
  }
}

export function getTestStatusColor(passed: boolean | null | undefined) {
  if (passed === true) {
    return 'success.main';
  } else if (passed === false) {
    return 'error.main';
  } else {
    return 'text.secondary';
  }
}

export function replaceTextWithEmojis(text: string) {
  text = text.replace(':white_check_mark:', '✅');
  text = text.replace(':x:', '❌');
  text = text.replace(':stopwatch:', '⏱️');
  return text;
}

export function removeBackticks(str: string) {
  return str.replace(/`/g, '');
}

export interface ICompletionDetails {
  completed_tasks?: string[];
  failed_tasks?: string[];
  timing_info?: string;
}

export async function downloadFile(url: string, filename: string) {
  try {
    const response = await fetch(url, { mode: 'cors' }); // Ensure CORS is handled
    if (!response.ok) {
      throw new Error(`Failed to fetch file: ${response.statusText}`);
    }

    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    // Clean up by revoking the temporary blob URL
    URL.revokeObjectURL(blobUrl);
    document.body.removeChild(a);
  } catch (error) {
    console.error('Error downloading the file:', error);
  }
}

export function isNumber(value: string | number | null | undefined): boolean {
  return typeof value === 'number' && isFinite(value);
}

export function isValidLimit(limit: number) {
  const availableLimits = [5, 10, 25];
  return availableLimits.includes(limit);
}

export function isValidPage(page: number) {
  return isNumber(page);
}
