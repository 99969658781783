import { useAuth0 } from '@auth0/auth0-react';
import StarIcon from '@mui/icons-material/Star';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Rating,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { EAssessmentDifficulty } from '../../constants/assessment';
import { EExpertiseLevels } from '../../constants/common';
import { AssessmentAlert } from '../AssessmentAlert';
import {
  assessmentFeedbackLabels,
  feedbackCharLimit,
  getLabelText,
  IAssessmentFeedbackFormProps,
} from './data';
import { submitTestReview } from './utils';

export default function AssessmentFeedbackForm({
  companyName,
  reviewerEmail,
  reviewerName,
  trialId,
  testName,
}: IAssessmentFeedbackFormProps) {
  const { getAccessTokenSilently } = useAuth0();
  const [difficulty, setDifficulty] = useState<number | null>(3);
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState<number | null>(3);
  const [hover, setHover] = useState(-1);
  const [expertiseLevel, setExpertiseLevel] = useState<number | null>(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>(
    'success'
  );

  const handleDifficultyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedValue = parseInt(event.target.value, 10);
    setDifficulty(isNaN(selectedValue) ? null : selectedValue);
  };

  const handleExpertiseChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedValue = parseInt(event.target.value, 10);
    setExpertiseLevel(isNaN(selectedValue) ? null : selectedValue);
  };

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= feedbackCharLimit) {
      setFeedback(event.target.value);
    }
  };

  const insertFeedback = useMutation(
    async () => {
      if (!rating || !difficulty || !expertiseLevel) {
        const errorMessage =
          'Please select at least experience rating, difficulty level, and expertise level.';
        throw new Error(errorMessage);
      }

      const token = await getAccessTokenSilently();

      const difficultyLabel = Object.keys(EAssessmentDifficulty)[
        Object.values(EAssessmentDifficulty).indexOf(difficulty)
      ];
      const expertiseLabel =
        Object.keys(EExpertiseLevels)[
          Object.values(EExpertiseLevels).indexOf(expertiseLevel)
        ];

      await submitTestReview(token, {
        comments: feedback.length ? feedback : null,
        company_name: companyName,
        difficulty: difficultyLabel,
        difficulty_id: difficulty,
        expertise_level: expertiseLabel,
        expertise_level_id: expertiseLevel,
        rating: rating,
        reviewer_email: reviewerEmail,
        reviewer_name: reviewerName,
        test: testName,
        trial_id: trialId,
      });
    },
    {
      onSuccess: () => {
        setAlertMessage('Thanks for taking the time to provide your review!');
        setAlertSeverity('success');
        setSnackbarOpen(true);
      },
      onError: (error: unknown) => {
        const errorMessage = (error as Error).message;
        setAlertMessage('Something went wrong. ' + errorMessage);
        setAlertSeverity('error');
        setSnackbarOpen(true);
      },
    }
  );
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          mt: 2,
          width: '100%',
        }}
      >
        <Typography
          variant="subtitle1"
          marginTop="1"
          gutterBottom
          align="center"
          fontWeight={550}
        >
          How would you rate your experience?
        </Typography>
        <Rating
          name="hover-feedback"
          value={rating}
          precision={1}
          getLabelText={getLabelText}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          emptyIcon={<StarIcon style={{ opacity: 0.5 }} fontSize="inherit" />}
          sx={{
            '& .MuiRating-iconFilled': {
              transition: 'none',
            },
            '& .MuiRating-iconHover': {
              transition: 'none',
            },
            '& .MuiRating-icon': {
              fontSize: '1.75rem',
            },
          }}
        />
        {rating !== null && (
          <Box sx={{ m: 1, textAlign: 'center' }}>
            {assessmentFeedbackLabels[hover !== -1 ? hover : rating]}
          </Box>
        )}

        <FormControl component="fieldset" sx={{ m: 2, alignItems: 'center' }}>
          <Typography
            variant="subtitle1"
            gutterBottom
            align="center"
            fontWeight={520}
          >
            How did you find the difficulty level of the test?
          </Typography>
          <RadioGroup
            row
            aria-labelledby="difficulty"
            name="difficulty"
            onChange={handleDifficultyChange}
          >
            {Object.entries(EAssessmentDifficulty).map(([difficulty, id]) => (
              <FormControlLabel
                key={id}
                value={id}
                control={<Radio />}
                label={difficulty}
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: '1.35rem',
                  },
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset" sx={{ m: 2, alignItems: 'center' }}>
          <Typography
            variant="subtitle1"
            gutterBottom
            align="center"
            fontWeight={520}
          >
            What is your expertise level?
          </Typography>
          <RadioGroup
            row
            aria-labelledby="expertise"
            name="expertise"
            onChange={handleExpertiseChange}
          >
            {Object.entries(EExpertiseLevels).map(([level, id]) => (
              <FormControlLabel
                key={id}
                value={id}
                control={<Radio />}
                label={level}
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: '1.35rem',
                  },
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <TextField
          fullWidth
          multiline
          rows={2}
          variant="outlined"
          placeholder="Share your thoughts about this experience..."
          value={feedback}
          onChange={handleFeedbackChange}
          helperText={`${feedback.length}/${feedbackCharLimit} characters`}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            mt: 1,
          }}
        >
          <Button
            id="assessment-submit-review"
            variant="outlined"
            color="inherit"
            disabled={insertFeedback.isLoading}
            onClick={() => {
              insertFeedback.mutate();
            }}
          >
            Submit Review
          </Button>
        </Box>
      </Box>
      <AssessmentAlert
        text={alertMessage}
        open={snackbarOpen}
        severity={alertSeverity}
        variant={'standard'}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
}
