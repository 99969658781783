import arrowForwardFill from '@iconify/icons-eva/arrow-forward-fill';
import copyOutline from '@iconify/icons-eva/copy-outline';
import linkedinIcon from '@iconify/icons-eva/linkedin-fill';
import personDeleteFill from '@iconify/icons-eva/person-delete-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import syncFill from '@iconify/icons-eva/sync-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import { Icon } from '@iconify/react';
import {
  Avatar,
  Box,
  Divider,
  LinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { ChangeEvent } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { TestTrialPartialFragment } from '../../../@generated/types';
import { MoreMenu } from '../../../components/MoreMenu';
import {
  calculateCompletionPercentage,
  formatCompanyRankingPositionDescription,
  getCompletionPercentageColor,
} from '../../../utils/common';
import useCandidateActions from '../hooks/useCandidatesActions';
import {
  formatDateToMonthDayYear,
  getDateTime,
  getTestTimeTaken,
} from '../utils';
import { ESortByKeys, ISortCriteria } from './data';
import SortableTableHeader from './SortTableHeader';

interface ICandidatesTable {
  testTrials: TestTrialPartialFragment[];
  page: number;
  limit: number;
  total?: number;
  loading?: boolean;
  disableMenuItems?: boolean;
  onSuccess(message: string): void;
  onError(error: string): void;
  onUserDelete(testTrial: TestTrialPartialFragment): void;
  onTestDelete(testTrial: TestTrialPartialFragment): void;
  onAddAnotherTest(testTrial: TestTrialPartialFragment): void;
  onTestReset(testTrial: TestTrialPartialFragment): void;
  onChangeLimit(limit: number): void;
  onChangePage(page: number): void;
  sortCriteria: ISortCriteria[];
  onSortChange: (sortCriteria: ISortCriteria[]) => void;
}

export default function CandidatesTable({
  testTrials,
  page,
  limit,
  total,
  loading = false,
  disableMenuItems = false,
  onSuccess,
  onError,
  onUserDelete,
  onTestDelete,
  onAddAnotherTest,
  onTestReset,
  onChangeLimit,
  onChangePage,
  sortCriteria,
  onSortChange,
}: ICandidatesTable) {
  const navigate = useNavigate();

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeLimit(parseInt(event.target.value, 10));
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    onChangePage(newPage);
  };

  const { resendInvite, handleCopyInviteUrl } = useCandidateActions({
    onError,
    onSuccess,
  });

  const getValidHref = (url: string) =>
    url.startsWith('http://') || url.startsWith('https://')
      ? url
      : `http://${url}`;

  const handleTableSortChange = (newSortKey: ESortByKeys) => {
    const existingCriteriaIndex = sortCriteria.findIndex(
      ({ key }) => key === newSortKey
    );

    let updatedCriteria: ISortCriteria[] = [...sortCriteria];

    if (existingCriteriaIndex !== -1) {
      const existingCriteria = updatedCriteria[existingCriteriaIndex];
      updatedCriteria[existingCriteriaIndex] = {
        key: existingCriteria.key.endsWith('Down')
          ? (existingCriteria.key.replace('Down', 'Up') as ESortByKeys)
          : (existingCriteria.key.replace('Up', 'Down') as ESortByKeys),
        order: existingCriteria.order === 'asc' ? 'desc' : 'asc',
      };
    } else {
      updatedCriteria.push({
        key: newSortKey,
        order: 'asc',
      });
    }

    updatedCriteria = updatedCriteria.filter(
      (criteria, index, self) =>
        index === self.findIndex((c) => c.key === criteria.key)
    );
    onSortChange(updatedCriteria);
  };

  return (
    <>
      <PerfectScrollbar>
        <Table sx={{ minWidth: 1100, tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  width: '285px',
                }}
              >
                <SortableTableHeader
                  label="Email"
                  sortKey={ESortByKeys.emailDown}
                  currentSortCriteria={sortCriteria}
                  onSortChange={handleTableSortChange}
                />
              </TableCell>
              <TableCell
                sx={{
                  width: '195px',
                }}
              >
                Test
              </TableCell>
              <TableCell
                sx={{
                  width: '90px',
                }}
              >
                <SortableTableHeader
                  label="Score"
                  sortKey={ESortByKeys.competitionScoreDown}
                  currentSortCriteria={sortCriteria}
                  onSortChange={handleTableSortChange}
                />
              </TableCell>

              <TableCell
                sx={{
                  width: '85px',
                }}
              >
                Global Rank
              </TableCell>

              <TableCell
                sx={{
                  width: '80px',
                }}
              >
                Status
              </TableCell>

              <TableCell
                sx={{
                  width: '100px',
                }}
              >
                <SortableTableHeader
                  label="Time Taken"
                  sortKey={ESortByKeys.timeDown}
                  currentSortCriteria={sortCriteria}
                  onSortChange={handleTableSortChange}
                />
              </TableCell>
              <TableCell
                sx={{
                  width: '95px',
                }}
              >
                <SortableTableHeader
                  label="Invited"
                  sortKey={ESortByKeys.invitedAtDown}
                  currentSortCriteria={sortCriteria}
                  onSortChange={handleTableSortChange}
                />
              </TableCell>
              <TableCell
                sx={{
                  width: '95px',
                }}
              >
                <SortableTableHeader
                  label="Last Login"
                  sortKey={ESortByKeys.loggedAtDown}
                  currentSortCriteria={sortCriteria}
                  onSortChange={handleTableSortChange}
                />
              </TableCell>
              <TableCell sx={{ textAlign: 'right', width: '95px' }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testTrials.map((testTrial) => {
              if (!testTrial.candidate) return null;
              const {
                id,
                passed,
                completed_tasks,
                total_tasks,
                candidate: {
                  email,
                  name: candidateName,
                  nickname: candidateNickName,
                  picture,
                  linkedin,
                  logged_at,
                  id: candidateId,
                },
                test,
                time_taken_seconds,
                invited_at,
                user_test_stat,
              } = testTrial;

              const [preTestName, name] = test.name.split(':');

              const percentage = calculateCompletionPercentage(
                completed_tasks || 0,
                total_tasks || 0
              );
              const percentageColor = getCompletionPercentageColor(percentage);

              return (
                <TableRow
                  hover
                  key={`testTrial${id}`}
                  onClick={() =>
                    navigate(`/company/candidates/${candidateId}/tests/${id}`)
                  }
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell
                    sx={{
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                      overflowWrap: 'break-word',
                      overflow: 'hidden',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar
                        alt={email}
                        src={picture ?? ''}
                        sx={{
                          width: '2.625rem',
                          height: '2.625rem',
                          mr: '1rem',
                        }}
                      />
                      <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: 'bold', fontSize: 14 }}
                          >
                            {candidateName && !candidateName.includes('@')
                              ? candidateName
                              : candidateNickName}
                          </Typography>
                          {linkedin && (
                            <Link
                              href={getValidHref(linkedin ?? '')}
                              target="_blank"
                              style={{
                                textDecoration: 'none',
                                marginTop: '0.25rem',
                                marginLeft: '0.25rem',
                                filter: 'grayscale(1)',
                              }}
                            >
                              <Icon
                                icon={linkedinIcon}
                                width="16"
                                height="16"
                              />
                            </Link>
                          )}
                        </Box>
                        <Typography variant="body2">{email}</Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {preTestName ? preTestName + ':' : test.name}
                    </Typography>
                    {name && (
                      <Typography variant="body2" sx={{ marginTop: 0.2 }}>
                        {name}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: passed !== null ? percentageColor : 'primary',
                    }}
                  >
                    {passed !== null ? `${percentage}%` : 'N/A'}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: passed !== null ? percentageColor : 'primary',
                    }}
                  >
                    {passed && user_test_stat && test.test_stat ? (
                      <Tooltip
                        title={formatCompanyRankingPositionDescription(
                          user_test_stat.ranking_position,
                          test.test_stat.total_passed
                        )}
                        arrow
                      >
                        <span>{user_test_stat?.ranking_position}</span>
                      </Tooltip>
                    ) : (
                      ''
                    )}
                  </TableCell>

                  <TableCell>
                    {passed === true
                      ? 'Passed'
                      : passed === false
                      ? 'Failed'
                      : 'Pending'}
                  </TableCell>

                  <TableCell>{getTestTimeTaken(time_taken_seconds)}</TableCell>

                  <TableCell>
                    <Tooltip title={getDateTime(invited_at)} placement="top">
                      <span>{formatDateToMonthDayYear(invited_at)}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={getDateTime(logged_at)} placement="top">
                      <span>{formatDateToMonthDayYear(logged_at)}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: 'right' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <MoreMenu
                      menuItems={[
                        {
                          icon: arrowForwardFill,
                          text: 'Go to test results',
                          onClick: () => {
                            navigate(
                              `/company/candidates/${candidateId}/tests/${id}`
                            );
                          },
                        },
                        {
                          icon: plusFill,
                          text: 'Add another test',
                          disabled: disableMenuItems,
                          onClick: () => {
                            onAddAnotherTest(testTrial);
                          },
                        },
                        {
                          icon: copyOutline,
                          text: 'Copy invite URL',
                          disabled: disableMenuItems,
                          onClick: () => {
                            handleCopyInviteUrl(testTrial);
                          },
                        },
                        {
                          icon: refreshFill,
                          text: 'Resend invite',
                          disabled: disableMenuItems,
                          onClick: () => {
                            resendInvite(testTrial);
                          },
                        },
                        ...(typeof testTrial.passed === 'boolean'
                          ? [
                              {
                                icon: syncFill,
                                text: 'Reset test result',
                                disabled: disableMenuItems,
                                onClick: () => {
                                  onTestReset(testTrial);
                                },
                              },
                            ]
                          : []),
                        {
                          icon: personDeleteFill,
                          text: 'Delete candidate',
                          onClick: () => {
                            onUserDelete(testTrial);
                          },
                        },
                        {
                          icon: trash2Fill,
                          text: 'Delete test',
                          onClick: () => {
                            onTestDelete(testTrial);
                          },
                        },
                      ]}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      {loading && <LinearProgress sx={{ width: '100%' }} />}
      {!loading && testTrials.length === 0 && (
        <>
          <Typography
            sx={{
              textAlign: 'center',
              p: 2,
              display: 'inline-flex',
              alignItems: 'center',
              gap: 1,
            }}
            variant="subtitle2"
            gutterBottom
          >
            No test results available. Check your filters or invite candidates
            to take tests and view their results here. You can also{' '}
            <ReactLink to="/company/test-catalog" style={{ color: 'black' }}>
              try the test
            </ReactLink>{' '}
            yourself to better understand them.
          </Typography>
          <Divider />
        </>
      )}
      {!loading && (
        <TablePagination
          component="div"
          count={total ?? testTrials.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      )}
    </>
  );
}
