/* eslint-disable */
/* istanbul ignore file */
import * as Types from './types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export const TestsCountFragmentDoc = gql`
    fragment TestsCount on test_trials_aggregate {
  aggregate {
    count
  }
}
    `;
export const Public_RecordingsFragmentFragmentDoc = gql`
    fragment public_recordingsFragment on public_recordings {
  url
  expires_at
  uid
}
    `;
export const UserTestStatsFragmentFragmentDoc = gql`
    fragment UserTestStatsFragment on user_test_stats {
  ranking_position
  total_attempts
}
    `;
export const UserTestTrialCandidatePartialFragmentDoc = gql`
    fragment UserTestTrialCandidatePartial on test_trials {
  candidate {
    id
    email
    name
    nickname
    picture
    auth0_id
    linkedin
    logged_at
    userhash
  }
}
    `;
export const Test_DifficultiesFragmentFragmentDoc = gql`
    fragment test_difficultiesFragment on test_difficulties {
  level
}
    `;
export const Test_StatsFragmentFragmentDoc = gql`
    fragment test_statsFragment on test_stats {
  average_passed_time
  pass_rate
  total_passed
}
    `;
export const Test_TypesFragmentFragmentDoc = gql`
    fragment test_typesFragment on test_types {
  type
}
    `;
export const UserTestTrialTestPartialFragmentDoc = gql`
    fragment UserTestTrialTestPartial on test_trials {
  test {
    brokee_id
    name
    id
    description_human
    arch_diagram_image
    description_long
    test_difficulty {
      ...test_difficultiesFragment
    }
    test_stat {
      ...test_statsFragment
    }
    test_type {
      ...test_typesFragment
    }
  }
}
    ${Test_DifficultiesFragmentFragmentDoc}
${Test_StatsFragmentFragmentDoc}
${Test_TypesFragmentFragmentDoc}`;
export const UserTestTrialIssuesPartialFragmentDoc = gql`
    fragment UserTestTrialIssuesPartial on test_trials {
  test_trial_issues(order_by: {issue: {title: asc}}) {
    issue {
      title
      issue_id
      explanation
    }
  }
}
    `;
export const Test_FeedbacksFragmentFragmentDoc = gql`
    fragment test_feedbacksFragment on test_feedbacks {
  id
  rating
  comments
}
    `;
export const UserTestTrialFeedbackFragmentDoc = gql`
    fragment UserTestTrialFeedback on test_trials {
  test_feedback {
    ...test_feedbacksFragment
  }
}
    ${Test_FeedbacksFragmentFragmentDoc}`;
export const UserTestTrialPartialFragmentDoc = gql`
    fragment UserTestTrialPartial on test_trials {
  id
  passed
  time_taken_seconds
  started_at
  invited_at
  submitted_at
  user_id
  total_tasks
  completed_tasks
  test_id
  completion_details
  completion_score
  ai_summary
  ai_questions
  public_recordings {
    ...public_recordingsFragment
  }
  user_test_stat {
    ...UserTestStatsFragment
  }
  ...UserTestTrialCandidatePartial
  ...UserTestTrialTestPartial
  ...UserTestTrialIssuesPartial
  ...UserTestTrialFeedback
}
    ${Public_RecordingsFragmentFragmentDoc}
${UserTestStatsFragmentFragmentDoc}
${UserTestTrialCandidatePartialFragmentDoc}
${UserTestTrialTestPartialFragmentDoc}
${UserTestTrialIssuesPartialFragmentDoc}
${UserTestTrialFeedbackFragmentDoc}`;
export const CandidateUserPartialFragmentDoc = gql`
    fragment CandidateUserPartial on test_trials {
  candidate {
    id
    email
    picture
    linkedin
  }
}
    `;
export const BadgesFragmentFragmentDoc = gql`
    fragment badgesFragment on badges {
  badge_id
  title
}
    `;
export const Badge_AwardsFragmentFragmentDoc = gql`
    fragment badge_awardsFragment on badge_awards {
  id
  public_url
  recipient_email
}
    `;
export const Tech_ServicesFragmentFragmentDoc = gql`
    fragment tech_servicesFragment on tech_services {
  name
}
    `;
export const Tech_Service_ComponentsFragmentFragmentDoc = gql`
    fragment tech_service_componentsFragment on tech_service_components {
  name
  description
}
    `;
export const Tech_ResourcesFragmentFragmentDoc = gql`
    fragment tech_resourcesFragment on tech_resources {
  name
  url
}
    `;
export const Tech_Service_Component_ResourcesFragmentFragmentDoc = gql`
    fragment tech_service_component_resourcesFragment on tech_service_component_resources {
  tech_resource {
    ...tech_resourcesFragment
  }
}
    ${Tech_ResourcesFragmentFragmentDoc}`;
export const Test_Tech_Services_ResourcesFragmentFragmentDoc = gql`
    fragment test_tech_services_resourcesFragment on test_tech_services_resources {
  tech_service {
    ...tech_servicesFragment
  }
  tech_service_component {
    ...tech_service_componentsFragment
  }
  tech_service_component_resources(order_by: {tech_resource: {name: asc}}) {
    ...tech_service_component_resourcesFragment
  }
}
    ${Tech_ServicesFragmentFragmentDoc}
${Tech_Service_ComponentsFragmentFragmentDoc}
${Tech_Service_Component_ResourcesFragmentFragmentDoc}`;
export const CandidateTestPartialFragmentDoc = gql`
    fragment CandidateTestPartial on test_trials {
  test {
    brokee_id
    name
    id
    test_difficulty {
      ...test_difficultiesFragment
    }
    badge {
      ...badgesFragment
    }
    badge_awards {
      ...badge_awardsFragment
    }
    test_tech_services_resources(order_by: {tech_service: {name: asc}}) {
      ...test_tech_services_resourcesFragment
    }
    test_stat {
      ...test_statsFragment
    }
  }
}
    ${Test_DifficultiesFragmentFragmentDoc}
${BadgesFragmentFragmentDoc}
${Badge_AwardsFragmentFragmentDoc}
${Test_Tech_Services_ResourcesFragmentFragmentDoc}
${Test_StatsFragmentFragmentDoc}`;
export const CandidateTestTrialPartialFragmentDoc = gql`
    fragment CandidateTestTrialPartial on test_trials {
  completed_tasks
  completion_score
  id
  invited_at
  passed
  submitted_at
  test_id
  time_taken_seconds
  total_tasks
  user_id
  user_test_stat {
    ...UserTestStatsFragment
  }
  ...CandidateUserPartial
  ...CandidateTestPartial
  ...UserTestTrialFeedback
}
    ${UserTestStatsFragmentFragmentDoc}
${CandidateUserPartialFragmentDoc}
${CandidateTestPartialFragmentDoc}
${UserTestTrialFeedbackFragmentDoc}`;
export const PersonalTestPartialFragmentDoc = gql`
    fragment PersonalTestPartial on test_trials {
  test {
    arch_diagram_image
    description_human
    description_long
    brokee_id
    name
    id
    test_difficulty {
      ...test_difficultiesFragment
    }
    badge {
      ...badgesFragment
    }
    badge_awards {
      ...badge_awardsFragment
    }
    test_tech_services_resources(order_by: {tech_service: {name: asc}}) {
      ...test_tech_services_resourcesFragment
    }
    test_stat {
      ...test_statsFragment
    }
  }
}
    ${Test_DifficultiesFragmentFragmentDoc}
${BadgesFragmentFragmentDoc}
${Badge_AwardsFragmentFragmentDoc}
${Test_Tech_Services_ResourcesFragmentFragmentDoc}
${Test_StatsFragmentFragmentDoc}`;
export const PersonalTestTrialPartialFragmentDoc = gql`
    fragment PersonalTestTrialPartial on test_trials {
  completed_tasks
  completion_details
  completion_score
  id
  invited_at
  passed
  submitted_at
  requester_id
  test_id
  time_taken_seconds
  total_tasks
  user_id
  user_test_stat {
    ...UserTestStatsFragment
  }
  ...CandidateUserPartial
  ...PersonalTestPartial
  ...UserTestTrialFeedback
}
    ${UserTestStatsFragmentFragmentDoc}
${CandidateUserPartialFragmentDoc}
${PersonalTestPartialFragmentDoc}
${UserTestTrialFeedbackFragmentDoc}`;
export const Onboarding_AnswersFragmentFragmentDoc = gql`
    fragment onboarding_answersFragment on onboarding_answers {
  id
  answer
  account_type_id
  is_other
  question_id
}
    `;
export const Onboarding_QuestionsFragmentFragmentDoc = gql`
    fragment onboarding_questionsFragment on onboarding_questions {
  id
  question
  onboarding_answers(
    where: {_or: [{account_type_id: {_eq: $account_type_id}}, {account_type_id: {_is_null: true}}]}
    order_by: {order: asc}
  ) {
    ...onboarding_answersFragment
    order
  }
}
    ${Onboarding_AnswersFragmentFragmentDoc}`;
export const Onboarding_Responses_Mutation_ResponseFragmentFragmentDoc = gql`
    fragment onboarding_responses_mutation_responseFragment on onboarding_responses_mutation_response {
  affected_rows
}
    `;
export const TestsFragmentFragmentDoc = gql`
    fragment testsFragment on tests {
  arch_diagram_image
  available_issues
  brokee_id
  description_long
  dynamic_issues
  featured_image
  issues_per_test
  infra_total_resources
  name
}
    `;
export const CompaniesFragmentFragmentDoc = gql`
    fragment companiesFragment on companies {
  name
}
    `;
export const Environment_TypesFragmentFragmentDoc = gql`
    fragment environment_typesFragment on environment_types {
  type
}
    `;
export const Test_TrialsFragmentFragmentDoc = gql`
    fragment test_trialsFragment on test_trials {
  id
  started_at
}
    `;
export const ProblemSetPartialFragmentDoc = gql`
    fragment ProblemSetPartial on assessment_links {
  test_issues {
    title
    explanation
    issue_id
  }
}
    `;
export const AssessmentLinksPartialFragmentDoc = gql`
    fragment AssessmentLinksPartial on assessment_links {
  link_id
  job_title
  max_applicants
  created_at
  expires_at
  is_active
  issues_list
  id
  test {
    id
    name
    brokee_id
    dynamic_issues
  }
  ...ProblemSetPartial
  number_of_candidates_aggregate: test_trials_aggregate {
    aggregate {
      count
    }
  }
  test_trials_aggregate(where: {submitted: {_eq: true}}) {
    aggregate {
      count
    }
  }
}
    ${ProblemSetPartialFragmentDoc}`;
export const CompanyPartialFragmentDoc = gql`
    fragment CompanyPartial on companies {
  id
  name
  stripe_customer_id
  stripe_billing_email
}
    `;
export const BillingIntervalPartialFragmentDoc = gql`
    fragment BillingIntervalPartial on billing_intervals {
  id
  interval
}
    `;
export const PricingPlansPartialFragmentDoc = gql`
    fragment PricingPlansPartial on pricing_plans {
  billing_interval_id
  created_at
  currency
  description
  feature_list
  id
  is_active
  name
  price
  updated_at
  is_preferred
  display_order
  call_to_action
  billing_interval {
    id
    interval
  }
}
    `;
export const SubscriptionPartialFragmentDoc = gql`
    fragment SubscriptionPartial on subscriptions {
  id
  stripe_subscription_id
  current_period_end
  current_period_start
}
    `;
export const TestTrialCandidatePartialFragmentDoc = gql`
    fragment TestTrialCandidatePartial on test_trials {
  candidate {
    id
    email
    name
    nickname
    picture
    auth0_id
    linkedin
    userhash
    logged_at
  }
}
    `;
export const TestTrialCompanyPartialFragmentDoc = gql`
    fragment TestTrialCompanyPartial on test_trials {
  company {
    name
    id
  }
}
    `;
export const TestTrialTestPartialFragmentDoc = gql`
    fragment TestTrialTestPartial on test_trials {
  test {
    brokee_id
    name
    test_stat {
      pass_rate
      total_passed
    }
  }
}
    `;
export const TestTrialRequesterPartialFragmentDoc = gql`
    fragment TestTrialRequesterPartial on test_trials {
  requester {
    id
    email
    name
    nickname
    picture
    linkedin
  }
}
    `;
export const TestTrialPartialFragmentDoc = gql`
    fragment TestTrialPartial on test_trials {
  id
  passed
  completed_tasks
  completion_score
  total_tasks
  time_taken_seconds
  invited_at
  requester_id
  user_id
  user_test_stat {
    ...UserTestStatsFragment
  }
  ...TestTrialCandidatePartial
  ...TestTrialCompanyPartial
  ...TestTrialTestPartial
  ...TestTrialRequesterPartial
}
    ${UserTestStatsFragmentFragmentDoc}
${TestTrialCandidatePartialFragmentDoc}
${TestTrialCompanyPartialFragmentDoc}
${TestTrialTestPartialFragmentDoc}
${TestTrialRequesterPartialFragmentDoc}`;
export const Test_Tech_RolesFragmentFragmentDoc = gql`
    fragment test_tech_rolesFragment on test_tech_roles {
  tech_role {
    name
  }
}
    `;
export const Test_Tech_ServicesFragmentFragmentDoc = gql`
    fragment test_tech_servicesFragment on test_tech_services {
  tech_service {
    name
  }
}
    `;
export const TestPartialFragmentDoc = gql`
    fragment TestPartial on tests {
  description_short
  featured_image
  brokee_id
  name
  status
  test_difficulty {
    ...test_difficultiesFragment
  }
  test_tech_roles(order_by: {tech_role: {name: asc}}) {
    ...test_tech_rolesFragment
  }
  test_tech_services(order_by: {tech_service: {name: asc}}) {
    ...test_tech_servicesFragment
  }
}
    ${Test_DifficultiesFragmentFragmentDoc}
${Test_Tech_RolesFragmentFragmentDoc}
${Test_Tech_ServicesFragmentFragmentDoc}`;
export const TestCatalogPartialsFragmentDoc = gql`
    fragment TestCatalogPartials on tests {
  brokee_id
  name
  requires_payment
  featured_image
  test_difficulty {
    ...test_difficultiesFragment
  }
  description_short
  description_human
  status
  dynamic_issues
}
    ${Test_DifficultiesFragmentFragmentDoc}`;
export const Test_CategoriesFragmentFragmentDoc = gql`
    fragment test_categoriesFragment on test_categories {
  name
}
    `;
export const Test_IssuesFragmentFragmentDoc = gql`
    fragment test_issuesFragment on test_issues {
  explanation
  issue_id
  title
}
    `;
export const TestPrivatePartialFragmentDoc = gql`
    fragment TestPrivatePartial on tests {
  brokee_id
  name
  description_human
  description_long
  arch_diagram_image
  requires_payment
  dynamic_issues
  issues_per_test
  test_type {
    ...test_typesFragment
  }
  test_category {
    ...test_categoriesFragment
  }
  test_difficulty {
    ...test_difficultiesFragment
  }
  test_tech_roles(order_by: {tech_role: {name: asc}}) {
    ...test_tech_rolesFragment
  }
  test_tech_services(order_by: {tech_service: {name: asc}}) {
    ...test_tech_servicesFragment
  }
  test_stat {
    average_passed_time
    pass_rate
  }
  test_issues(order_by: {title: asc}) {
    ...test_issuesFragment
  }
  test_tech_services_resources(order_by: {tech_service: {name: asc}}) {
    ...test_tech_services_resourcesFragment
  }
}
    ${Test_TypesFragmentFragmentDoc}
${Test_CategoriesFragmentFragmentDoc}
${Test_DifficultiesFragmentFragmentDoc}
${Test_Tech_RolesFragmentFragmentDoc}
${Test_Tech_ServicesFragmentFragmentDoc}
${Test_IssuesFragmentFragmentDoc}
${Test_Tech_Services_ResourcesFragmentFragmentDoc}`;
export const Test_Trials_AggregateCountFragmentFragmentDoc = gql`
    fragment test_trials_aggregateCountFragment on test_trials_aggregate {
  aggregate {
    count
  }
}
    `;
export const Test_Trials_AggregateFragmentFragmentDoc = gql`
    fragment test_trials_aggregateFragment on test_trials_aggregate {
  aggregate {
    count
    min {
      time_taken_seconds
    }
    avg {
      time_taken_seconds
    }
  }
}
    `;
export const TransactionsPartialFragmentDoc = gql`
    fragment TransactionsPartial on transactions {
  amount
  description
  currency
  status
  stripe_charge_id
  transaction_type
  id
  created_at
  receipt_url
}
    `;
export const UsersPartialFragmentDoc = gql`
    fragment UsersPartial on users {
  auth0_id
  email
  linkedin
  name
  nickname
  picture
  userhash
  company_role_id
  id
  created_at
  logged_at
  company_id
}
    `;
export const CompanyRolePartialFragmentDoc = gql`
    fragment companyRolePartial on company_roles {
  name
}
    `;
export const InsertTestFeedbackDocument = gql`
    mutation InsertTestFeedback($object: test_feedbacks_insert_input!) {
  insert_test_feedbacks_one(
    object: $object
    on_conflict: {constraint: test_feedbacks_trial_id_key, update_columns: [comments, rating]}
  ) {
    id
  }
}
    `;
export type InsertTestFeedbackMutationFn = ApolloReactCommon.MutationFunction<Types.InsertTestFeedbackMutation, Types.InsertTestFeedbackMutationVariables>;

/**
 * __useInsertTestFeedbackMutation__
 *
 * To run a mutation, you first call `useInsertTestFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTestFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTestFeedbackMutation, { data, loading, error }] = useInsertTestFeedbackMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertTestFeedbackMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.InsertTestFeedbackMutation, Types.InsertTestFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.InsertTestFeedbackMutation, Types.InsertTestFeedbackMutationVariables>(InsertTestFeedbackDocument, options);
      }
export type InsertTestFeedbackMutationHookResult = ReturnType<typeof useInsertTestFeedbackMutation>;
export type InsertTestFeedbackMutationResult = ApolloReactCommon.MutationResult<Types.InsertTestFeedbackMutation>;
export type InsertTestFeedbackMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.InsertTestFeedbackMutation, Types.InsertTestFeedbackMutationVariables>;
export const GetCandidatesStatisticsDocument = gql`
    query GetCandidatesStatistics($company_id: Int!) {
  total_invented: test_trials_aggregate(
    where: {company_id: {_eq: $company_id}, deleted_at: {_is_null: true}, candidate: {company_role: {name: {_eq: "candidate"}}}}
  ) {
    ...TestsCount
  }
  total_passed: test_trials_aggregate(
    where: {company_id: {_eq: $company_id}, passed: {_eq: true}, deleted_at: {_is_null: true}, candidate: {company_role: {name: {_eq: "candidate"}}}}
  ) {
    ...TestsCount
  }
  total_failed: test_trials_aggregate(
    where: {_and: {company_id: {_eq: $company_id}, passed: {_eq: false}, deleted_at: {_is_null: true}, candidate: {company_role: {name: {_eq: "candidate"}}}}}
  ) {
    ...TestsCount
  }
}
    ${TestsCountFragmentDoc}`;

/**
 * __useGetCandidatesStatisticsQuery__
 *
 * To run a query within a React component, call `useGetCandidatesStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidatesStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidatesStatisticsQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetCandidatesStatisticsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.GetCandidatesStatisticsQuery, Types.GetCandidatesStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetCandidatesStatisticsQuery, Types.GetCandidatesStatisticsQueryVariables>(GetCandidatesStatisticsDocument, options);
      }
export function useGetCandidatesStatisticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetCandidatesStatisticsQuery, Types.GetCandidatesStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetCandidatesStatisticsQuery, Types.GetCandidatesStatisticsQueryVariables>(GetCandidatesStatisticsDocument, options);
        }
export type GetCandidatesStatisticsQueryHookResult = ReturnType<typeof useGetCandidatesStatisticsQuery>;
export type GetCandidatesStatisticsLazyQueryHookResult = ReturnType<typeof useGetCandidatesStatisticsLazyQuery>;
export type GetCandidatesStatisticsQueryResult = ApolloReactCommon.QueryResult<Types.GetCandidatesStatisticsQuery, Types.GetCandidatesStatisticsQueryVariables>;
export const GetTestTrialByIdDocument = gql`
    query GetTestTrialById($id: Int) {
  test_trials(where: {id: {_eq: $id}, deleted_at: {_is_null: true}}) {
    ...UserTestTrialPartial
  }
}
    ${UserTestTrialPartialFragmentDoc}`;

/**
 * __useGetTestTrialByIdQuery__
 *
 * To run a query within a React component, call `useGetTestTrialByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTestTrialByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestTrialByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTestTrialByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GetTestTrialByIdQuery, Types.GetTestTrialByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetTestTrialByIdQuery, Types.GetTestTrialByIdQueryVariables>(GetTestTrialByIdDocument, options);
      }
export function useGetTestTrialByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetTestTrialByIdQuery, Types.GetTestTrialByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetTestTrialByIdQuery, Types.GetTestTrialByIdQueryVariables>(GetTestTrialByIdDocument, options);
        }
export type GetTestTrialByIdQueryHookResult = ReturnType<typeof useGetTestTrialByIdQuery>;
export type GetTestTrialByIdLazyQueryHookResult = ReturnType<typeof useGetTestTrialByIdLazyQuery>;
export type GetTestTrialByIdQueryResult = ApolloReactCommon.QueryResult<Types.GetTestTrialByIdQuery, Types.GetTestTrialByIdQueryVariables>;
export const GetCandidateTrialByIdDocument = gql`
    subscription GetCandidateTrialById($id: Int) {
  test_trials(where: {id: {_eq: $id}, deleted_at: {_is_null: true}}) {
    ...CandidateTestTrialPartial
  }
}
    ${CandidateTestTrialPartialFragmentDoc}`;

/**
 * __useGetCandidateTrialByIdSubscription__
 *
 * To run a query within a React component, call `useGetCandidateTrialByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateTrialByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateTrialByIdSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCandidateTrialByIdSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<Types.GetCandidateTrialByIdSubscription, Types.GetCandidateTrialByIdSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<Types.GetCandidateTrialByIdSubscription, Types.GetCandidateTrialByIdSubscriptionVariables>(GetCandidateTrialByIdDocument, options);
      }
export type GetCandidateTrialByIdSubscriptionHookResult = ReturnType<typeof useGetCandidateTrialByIdSubscription>;
export type GetCandidateTrialByIdSubscriptionResult = ApolloReactCommon.SubscriptionResult<Types.GetCandidateTrialByIdSubscription>;
export const GetPersonalTrialByIdDocument = gql`
    subscription GetPersonalTrialById($id: Int) {
  test_trials(where: {id: {_eq: $id}, deleted_at: {_is_null: true}}) {
    ...PersonalTestTrialPartial
  }
}
    ${PersonalTestTrialPartialFragmentDoc}`;

/**
 * __useGetPersonalTrialByIdSubscription__
 *
 * To run a query within a React component, call `useGetPersonalTrialByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonalTrialByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonalTrialByIdSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPersonalTrialByIdSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<Types.GetPersonalTrialByIdSubscription, Types.GetPersonalTrialByIdSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<Types.GetPersonalTrialByIdSubscription, Types.GetPersonalTrialByIdSubscriptionVariables>(GetPersonalTrialByIdDocument, options);
      }
export type GetPersonalTrialByIdSubscriptionHookResult = ReturnType<typeof useGetPersonalTrialByIdSubscription>;
export type GetPersonalTrialByIdSubscriptionResult = ApolloReactCommon.SubscriptionResult<Types.GetPersonalTrialByIdSubscription>;
export const GetOnboardingQuestionsDocument = gql`
    query GetOnboardingQuestions($account_type_id: Int!) {
  onboarding_questions(order_by: {order: asc}) {
    ...onboarding_questionsFragment
  }
}
    ${Onboarding_QuestionsFragmentFragmentDoc}`;

/**
 * __useGetOnboardingQuestionsQuery__
 *
 * To run a query within a React component, call `useGetOnboardingQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingQuestionsQuery({
 *   variables: {
 *      account_type_id: // value for 'account_type_id'
 *   },
 * });
 */
export function useGetOnboardingQuestionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.GetOnboardingQuestionsQuery, Types.GetOnboardingQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetOnboardingQuestionsQuery, Types.GetOnboardingQuestionsQueryVariables>(GetOnboardingQuestionsDocument, options);
      }
export function useGetOnboardingQuestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetOnboardingQuestionsQuery, Types.GetOnboardingQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetOnboardingQuestionsQuery, Types.GetOnboardingQuestionsQueryVariables>(GetOnboardingQuestionsDocument, options);
        }
export type GetOnboardingQuestionsQueryHookResult = ReturnType<typeof useGetOnboardingQuestionsQuery>;
export type GetOnboardingQuestionsLazyQueryHookResult = ReturnType<typeof useGetOnboardingQuestionsLazyQuery>;
export type GetOnboardingQuestionsQueryResult = ApolloReactCommon.QueryResult<Types.GetOnboardingQuestionsQuery, Types.GetOnboardingQuestionsQueryVariables>;
export const InsertOnboardingResponsesDocument = gql`
    mutation InsertOnboardingResponses($objects: [onboarding_responses_insert_input!]!) {
  insert_onboarding_responses(objects: $objects) {
    ...onboarding_responses_mutation_responseFragment
  }
}
    ${Onboarding_Responses_Mutation_ResponseFragmentFragmentDoc}`;
export type InsertOnboardingResponsesMutationFn = ApolloReactCommon.MutationFunction<Types.InsertOnboardingResponsesMutation, Types.InsertOnboardingResponsesMutationVariables>;

/**
 * __useInsertOnboardingResponsesMutation__
 *
 * To run a mutation, you first call `useInsertOnboardingResponsesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertOnboardingResponsesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOnboardingResponsesMutation, { data, loading, error }] = useInsertOnboardingResponsesMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertOnboardingResponsesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.InsertOnboardingResponsesMutation, Types.InsertOnboardingResponsesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.InsertOnboardingResponsesMutation, Types.InsertOnboardingResponsesMutationVariables>(InsertOnboardingResponsesDocument, options);
      }
export type InsertOnboardingResponsesMutationHookResult = ReturnType<typeof useInsertOnboardingResponsesMutation>;
export type InsertOnboardingResponsesMutationResult = ApolloReactCommon.MutationResult<Types.InsertOnboardingResponsesMutation>;
export type InsertOnboardingResponsesMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.InsertOnboardingResponsesMutation, Types.InsertOnboardingResponsesMutationVariables>;
export const GetPublicRecordingsDocument = gql`
    query GetPublicRecordings($uid: uuid) {
  public_recordings(where: {uid: {_eq: $uid}}) {
    ...public_recordingsFragment
  }
}
    ${Public_RecordingsFragmentFragmentDoc}`;

/**
 * __useGetPublicRecordingsQuery__
 *
 * To run a query within a React component, call `useGetPublicRecordingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicRecordingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicRecordingsQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetPublicRecordingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GetPublicRecordingsQuery, Types.GetPublicRecordingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetPublicRecordingsQuery, Types.GetPublicRecordingsQueryVariables>(GetPublicRecordingsDocument, options);
      }
export function useGetPublicRecordingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetPublicRecordingsQuery, Types.GetPublicRecordingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetPublicRecordingsQuery, Types.GetPublicRecordingsQueryVariables>(GetPublicRecordingsDocument, options);
        }
export type GetPublicRecordingsQueryHookResult = ReturnType<typeof useGetPublicRecordingsQuery>;
export type GetPublicRecordingsLazyQueryHookResult = ReturnType<typeof useGetPublicRecordingsLazyQuery>;
export type GetPublicRecordingsQueryResult = ApolloReactCommon.QueryResult<Types.GetPublicRecordingsQuery, Types.GetPublicRecordingsQueryVariables>;
export const GetUserAssessmentDataDocument = gql`
    query GetUserAssessmentData($brokee_id: Int, $user_id: Int) {
  tests(where: {brokee_id: {_eq: $brokee_id}, status: {_eq: "ready"}}) {
    ...testsFragment
    test_trials(
      order_by: {invited_at: desc, id: desc}
      where: {_and: {candidate: {id: {_eq: $user_id}}, deleted_at: {_is_null: true}, _or: [{submitted: {_is_null: true}}, {submitted: {_eq: false}}]}}
    ) {
      id
      company_id
      company {
        ...companiesFragment
      }
      started_at
      submitted
    }
    environment_type {
      ...environment_typesFragment
    }
    test_category {
      ...test_categoriesFragment
    }
  }
}
    ${TestsFragmentFragmentDoc}
${CompaniesFragmentFragmentDoc}
${Environment_TypesFragmentFragmentDoc}
${Test_CategoriesFragmentFragmentDoc}`;

/**
 * __useGetUserAssessmentDataQuery__
 *
 * To run a query within a React component, call `useGetUserAssessmentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAssessmentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAssessmentDataQuery({
 *   variables: {
 *      brokee_id: // value for 'brokee_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetUserAssessmentDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GetUserAssessmentDataQuery, Types.GetUserAssessmentDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetUserAssessmentDataQuery, Types.GetUserAssessmentDataQueryVariables>(GetUserAssessmentDataDocument, options);
      }
export function useGetUserAssessmentDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetUserAssessmentDataQuery, Types.GetUserAssessmentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetUserAssessmentDataQuery, Types.GetUserAssessmentDataQueryVariables>(GetUserAssessmentDataDocument, options);
        }
export type GetUserAssessmentDataQueryHookResult = ReturnType<typeof useGetUserAssessmentDataQuery>;
export type GetUserAssessmentDataLazyQueryHookResult = ReturnType<typeof useGetUserAssessmentDataLazyQuery>;
export type GetUserAssessmentDataQueryResult = ApolloReactCommon.QueryResult<Types.GetUserAssessmentDataQuery, Types.GetUserAssessmentDataQueryVariables>;
export const GetTrialIssuesDocument = gql`
    query GetTrialIssues($trialId: Int!) {
  test_trial_issues(where: {test_trial_id: {_eq: $trialId}}) {
    test_issue_id
  }
}
    `;

/**
 * __useGetTrialIssuesQuery__
 *
 * To run a query within a React component, call `useGetTrialIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrialIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrialIssuesQuery({
 *   variables: {
 *      trialId: // value for 'trialId'
 *   },
 * });
 */
export function useGetTrialIssuesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.GetTrialIssuesQuery, Types.GetTrialIssuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetTrialIssuesQuery, Types.GetTrialIssuesQueryVariables>(GetTrialIssuesDocument, options);
      }
export function useGetTrialIssuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetTrialIssuesQuery, Types.GetTrialIssuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetTrialIssuesQuery, Types.GetTrialIssuesQueryVariables>(GetTrialIssuesDocument, options);
        }
export type GetTrialIssuesQueryHookResult = ReturnType<typeof useGetTrialIssuesQuery>;
export type GetTrialIssuesLazyQueryHookResult = ReturnType<typeof useGetTrialIssuesLazyQuery>;
export type GetTrialIssuesQueryResult = ApolloReactCommon.QueryResult<Types.GetTrialIssuesQuery, Types.GetTrialIssuesQueryVariables>;
export const UpdateTrialStartDateDocument = gql`
    mutation UpdateTrialStartDate($id: Int!, $input: test_trials_set_input!) {
  update_test_trials_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...test_trialsFragment
  }
}
    ${Test_TrialsFragmentFragmentDoc}`;
export type UpdateTrialStartDateMutationFn = ApolloReactCommon.MutationFunction<Types.UpdateTrialStartDateMutation, Types.UpdateTrialStartDateMutationVariables>;

/**
 * __useUpdateTrialStartDateMutation__
 *
 * To run a mutation, you first call `useUpdateTrialStartDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrialStartDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrialStartDateMutation, { data, loading, error }] = useUpdateTrialStartDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTrialStartDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UpdateTrialStartDateMutation, Types.UpdateTrialStartDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.UpdateTrialStartDateMutation, Types.UpdateTrialStartDateMutationVariables>(UpdateTrialStartDateDocument, options);
      }
export type UpdateTrialStartDateMutationHookResult = ReturnType<typeof useUpdateTrialStartDateMutation>;
export type UpdateTrialStartDateMutationResult = ApolloReactCommon.MutationResult<Types.UpdateTrialStartDateMutation>;
export type UpdateTrialStartDateMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UpdateTrialStartDateMutation, Types.UpdateTrialStartDateMutationVariables>;
export const GetAssessmentLinksByCompanyIdDocument = gql`
    query GetAssessmentLinksByCompanyId($company_id: Int) {
  assessment_links(
    where: {company_id: {_eq: $company_id}}
    order_by: {created_at: desc}
  ) {
    ...AssessmentLinksPartial
  }
}
    ${AssessmentLinksPartialFragmentDoc}`;

/**
 * __useGetAssessmentLinksByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetAssessmentLinksByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentLinksByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentLinksByCompanyIdQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetAssessmentLinksByCompanyIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GetAssessmentLinksByCompanyIdQuery, Types.GetAssessmentLinksByCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetAssessmentLinksByCompanyIdQuery, Types.GetAssessmentLinksByCompanyIdQueryVariables>(GetAssessmentLinksByCompanyIdDocument, options);
      }
export function useGetAssessmentLinksByCompanyIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetAssessmentLinksByCompanyIdQuery, Types.GetAssessmentLinksByCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetAssessmentLinksByCompanyIdQuery, Types.GetAssessmentLinksByCompanyIdQueryVariables>(GetAssessmentLinksByCompanyIdDocument, options);
        }
export type GetAssessmentLinksByCompanyIdQueryHookResult = ReturnType<typeof useGetAssessmentLinksByCompanyIdQuery>;
export type GetAssessmentLinksByCompanyIdLazyQueryHookResult = ReturnType<typeof useGetAssessmentLinksByCompanyIdLazyQuery>;
export type GetAssessmentLinksByCompanyIdQueryResult = ApolloReactCommon.QueryResult<Types.GetAssessmentLinksByCompanyIdQuery, Types.GetAssessmentLinksByCompanyIdQueryVariables>;
export const UpdateAssessmentLinksByIdDocument = gql`
    mutation UpdateAssessmentLinksById($id: Int, $is_active: Boolean) {
  update_assessment_links(where: {id: {_eq: $id}}, _set: {is_active: $is_active}) {
    affected_rows
  }
}
    `;
export type UpdateAssessmentLinksByIdMutationFn = ApolloReactCommon.MutationFunction<Types.UpdateAssessmentLinksByIdMutation, Types.UpdateAssessmentLinksByIdMutationVariables>;

/**
 * __useUpdateAssessmentLinksByIdMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentLinksByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentLinksByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentLinksByIdMutation, { data, loading, error }] = useUpdateAssessmentLinksByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      is_active: // value for 'is_active'
 *   },
 * });
 */
export function useUpdateAssessmentLinksByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UpdateAssessmentLinksByIdMutation, Types.UpdateAssessmentLinksByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.UpdateAssessmentLinksByIdMutation, Types.UpdateAssessmentLinksByIdMutationVariables>(UpdateAssessmentLinksByIdDocument, options);
      }
export type UpdateAssessmentLinksByIdMutationHookResult = ReturnType<typeof useUpdateAssessmentLinksByIdMutation>;
export type UpdateAssessmentLinksByIdMutationResult = ApolloReactCommon.MutationResult<Types.UpdateAssessmentLinksByIdMutation>;
export type UpdateAssessmentLinksByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UpdateAssessmentLinksByIdMutation, Types.UpdateAssessmentLinksByIdMutationVariables>;
export const UpdateAssessmentLinkDocument = gql`
    mutation UpdateAssessmentLink($id: Int!, $job_title: String, $expires_at: timestamptz, $max_applicants: Int) {
  update_assessment_links(
    where: {id: {_eq: $id}}
    _set: {job_title: $job_title, max_applicants: $max_applicants, expires_at: $expires_at}
  ) {
    affected_rows
    returning {
      ...AssessmentLinksPartial
    }
  }
}
    ${AssessmentLinksPartialFragmentDoc}`;
export type UpdateAssessmentLinkMutationFn = ApolloReactCommon.MutationFunction<Types.UpdateAssessmentLinkMutation, Types.UpdateAssessmentLinkMutationVariables>;

/**
 * __useUpdateAssessmentLinkMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentLinkMutation, { data, loading, error }] = useUpdateAssessmentLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      job_title: // value for 'job_title'
 *      expires_at: // value for 'expires_at'
 *      max_applicants: // value for 'max_applicants'
 *   },
 * });
 */
export function useUpdateAssessmentLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UpdateAssessmentLinkMutation, Types.UpdateAssessmentLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.UpdateAssessmentLinkMutation, Types.UpdateAssessmentLinkMutationVariables>(UpdateAssessmentLinkDocument, options);
      }
export type UpdateAssessmentLinkMutationHookResult = ReturnType<typeof useUpdateAssessmentLinkMutation>;
export type UpdateAssessmentLinkMutationResult = ApolloReactCommon.MutationResult<Types.UpdateAssessmentLinkMutation>;
export type UpdateAssessmentLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UpdateAssessmentLinkMutation, Types.UpdateAssessmentLinkMutationVariables>;
export const SubscribeAssessmentLinksCountByFilterDocument = gql`
    subscription SubscribeAssessmentLinksCountByFilter($filter: assessment_links_bool_exp) {
  assessment_links_aggregate(where: $filter) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useSubscribeAssessmentLinksCountByFilterSubscription__
 *
 * To run a query within a React component, call `useSubscribeAssessmentLinksCountByFilterSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeAssessmentLinksCountByFilterSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeAssessmentLinksCountByFilterSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSubscribeAssessmentLinksCountByFilterSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<Types.SubscribeAssessmentLinksCountByFilterSubscription, Types.SubscribeAssessmentLinksCountByFilterSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<Types.SubscribeAssessmentLinksCountByFilterSubscription, Types.SubscribeAssessmentLinksCountByFilterSubscriptionVariables>(SubscribeAssessmentLinksCountByFilterDocument, options);
      }
export type SubscribeAssessmentLinksCountByFilterSubscriptionHookResult = ReturnType<typeof useSubscribeAssessmentLinksCountByFilterSubscription>;
export type SubscribeAssessmentLinksCountByFilterSubscriptionResult = ApolloReactCommon.SubscriptionResult<Types.SubscribeAssessmentLinksCountByFilterSubscription>;
export const SubscribeAssessmentLinksByFilterDocument = gql`
    subscription SubscribeAssessmentLinksByFilter($filter: assessment_links_bool_exp, $limit: Int, $offset: Int) {
  assessment_links(
    where: $filter
    order_by: {created_at: desc}
    limit: $limit
    offset: $offset
  ) {
    ...AssessmentLinksPartial
  }
}
    ${AssessmentLinksPartialFragmentDoc}`;

/**
 * __useSubscribeAssessmentLinksByFilterSubscription__
 *
 * To run a query within a React component, call `useSubscribeAssessmentLinksByFilterSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeAssessmentLinksByFilterSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeAssessmentLinksByFilterSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSubscribeAssessmentLinksByFilterSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<Types.SubscribeAssessmentLinksByFilterSubscription, Types.SubscribeAssessmentLinksByFilterSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<Types.SubscribeAssessmentLinksByFilterSubscription, Types.SubscribeAssessmentLinksByFilterSubscriptionVariables>(SubscribeAssessmentLinksByFilterDocument, options);
      }
export type SubscribeAssessmentLinksByFilterSubscriptionHookResult = ReturnType<typeof useSubscribeAssessmentLinksByFilterSubscription>;
export type SubscribeAssessmentLinksByFilterSubscriptionResult = ApolloReactCommon.SubscriptionResult<Types.SubscribeAssessmentLinksByFilterSubscription>;
export const GetCompanyDetailsByCompanyIdDocument = gql`
    query GetCompanyDetailsByCompanyId($company_id: Int) {
  companies(where: {id: {_eq: $company_id}}) {
    ...CompanyPartial
  }
}
    ${CompanyPartialFragmentDoc}`;

/**
 * __useGetCompanyDetailsByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyDetailsByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyDetailsByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyDetailsByCompanyIdQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetCompanyDetailsByCompanyIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GetCompanyDetailsByCompanyIdQuery, Types.GetCompanyDetailsByCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetCompanyDetailsByCompanyIdQuery, Types.GetCompanyDetailsByCompanyIdQueryVariables>(GetCompanyDetailsByCompanyIdDocument, options);
      }
export function useGetCompanyDetailsByCompanyIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetCompanyDetailsByCompanyIdQuery, Types.GetCompanyDetailsByCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetCompanyDetailsByCompanyIdQuery, Types.GetCompanyDetailsByCompanyIdQueryVariables>(GetCompanyDetailsByCompanyIdDocument, options);
        }
export type GetCompanyDetailsByCompanyIdQueryHookResult = ReturnType<typeof useGetCompanyDetailsByCompanyIdQuery>;
export type GetCompanyDetailsByCompanyIdLazyQueryHookResult = ReturnType<typeof useGetCompanyDetailsByCompanyIdLazyQuery>;
export type GetCompanyDetailsByCompanyIdQueryResult = ApolloReactCommon.QueryResult<Types.GetCompanyDetailsByCompanyIdQuery, Types.GetCompanyDetailsByCompanyIdQueryVariables>;
export const GetPricingPlansDocument = gql`
    query GetPricingPlans($accountTypeIds: [Int!]) {
  pricing_plans(
    where: {_and: {is_active: {_eq: true}, _or: [{account_type_id: {_in: $accountTypeIds}}, {account_type_id: {_is_null: true}}]}}
    order_by: {display_order: asc}
  ) {
    ...PricingPlansPartial
    billing_interval {
      ...BillingIntervalPartial
    }
  }
}
    ${PricingPlansPartialFragmentDoc}
${BillingIntervalPartialFragmentDoc}`;

/**
 * __useGetPricingPlansQuery__
 *
 * To run a query within a React component, call `useGetPricingPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPricingPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPricingPlansQuery({
 *   variables: {
 *      accountTypeIds: // value for 'accountTypeIds'
 *   },
 * });
 */
export function useGetPricingPlansQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GetPricingPlansQuery, Types.GetPricingPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetPricingPlansQuery, Types.GetPricingPlansQueryVariables>(GetPricingPlansDocument, options);
      }
export function useGetPricingPlansLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetPricingPlansQuery, Types.GetPricingPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetPricingPlansQuery, Types.GetPricingPlansQueryVariables>(GetPricingPlansDocument, options);
        }
export type GetPricingPlansQueryHookResult = ReturnType<typeof useGetPricingPlansQuery>;
export type GetPricingPlansLazyQueryHookResult = ReturnType<typeof useGetPricingPlansLazyQuery>;
export type GetPricingPlansQueryResult = ApolloReactCommon.QueryResult<Types.GetPricingPlansQuery, Types.GetPricingPlansQueryVariables>;
export const GetActiveSubscriptionByCompanyIdDocument = gql`
    query GetActiveSubscriptionByCompanyId($company_id: Int) {
  subscriptions(where: {company_id: {_eq: $company_id}, status: {_eq: "active"}}) {
    ...SubscriptionPartial
  }
}
    ${SubscriptionPartialFragmentDoc}`;

/**
 * __useGetActiveSubscriptionByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetActiveSubscriptionByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveSubscriptionByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveSubscriptionByCompanyIdQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetActiveSubscriptionByCompanyIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GetActiveSubscriptionByCompanyIdQuery, Types.GetActiveSubscriptionByCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetActiveSubscriptionByCompanyIdQuery, Types.GetActiveSubscriptionByCompanyIdQueryVariables>(GetActiveSubscriptionByCompanyIdDocument, options);
      }
export function useGetActiveSubscriptionByCompanyIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetActiveSubscriptionByCompanyIdQuery, Types.GetActiveSubscriptionByCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetActiveSubscriptionByCompanyIdQuery, Types.GetActiveSubscriptionByCompanyIdQueryVariables>(GetActiveSubscriptionByCompanyIdDocument, options);
        }
export type GetActiveSubscriptionByCompanyIdQueryHookResult = ReturnType<typeof useGetActiveSubscriptionByCompanyIdQuery>;
export type GetActiveSubscriptionByCompanyIdLazyQueryHookResult = ReturnType<typeof useGetActiveSubscriptionByCompanyIdLazyQuery>;
export type GetActiveSubscriptionByCompanyIdQueryResult = ApolloReactCommon.QueryResult<Types.GetActiveSubscriptionByCompanyIdQuery, Types.GetActiveSubscriptionByCompanyIdQueryVariables>;
export const GetUniqueTestTrialsByIdDocument = gql`
    query GetUniqueTestTrialsById {
  test_trials(distinct_on: test_id, where: {deleted_at: {_is_null: true}}) {
    id
    ...TestTrialTestPartial
  }
}
    ${TestTrialTestPartialFragmentDoc}`;

/**
 * __useGetUniqueTestTrialsByIdQuery__
 *
 * To run a query within a React component, call `useGetUniqueTestTrialsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniqueTestTrialsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniqueTestTrialsByIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUniqueTestTrialsByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GetUniqueTestTrialsByIdQuery, Types.GetUniqueTestTrialsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetUniqueTestTrialsByIdQuery, Types.GetUniqueTestTrialsByIdQueryVariables>(GetUniqueTestTrialsByIdDocument, options);
      }
export function useGetUniqueTestTrialsByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetUniqueTestTrialsByIdQuery, Types.GetUniqueTestTrialsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetUniqueTestTrialsByIdQuery, Types.GetUniqueTestTrialsByIdQueryVariables>(GetUniqueTestTrialsByIdDocument, options);
        }
export type GetUniqueTestTrialsByIdQueryHookResult = ReturnType<typeof useGetUniqueTestTrialsByIdQuery>;
export type GetUniqueTestTrialsByIdLazyQueryHookResult = ReturnType<typeof useGetUniqueTestTrialsByIdLazyQuery>;
export type GetUniqueTestTrialsByIdQueryResult = ApolloReactCommon.QueryResult<Types.GetUniqueTestTrialsByIdQuery, Types.GetUniqueTestTrialsByIdQueryVariables>;
export const SubscribeTestTrialsCountDocument = gql`
    subscription SubscribeTestTrialsCount($filter: test_trials_bool_exp, $order_by: [test_trials_order_by!]) {
  test_trials_aggregate(where: $filter, order_by: $order_by) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useSubscribeTestTrialsCountSubscription__
 *
 * To run a query within a React component, call `useSubscribeTestTrialsCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeTestTrialsCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeTestTrialsCountSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeTestTrialsCountSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<Types.SubscribeTestTrialsCountSubscription, Types.SubscribeTestTrialsCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<Types.SubscribeTestTrialsCountSubscription, Types.SubscribeTestTrialsCountSubscriptionVariables>(SubscribeTestTrialsCountDocument, options);
      }
export type SubscribeTestTrialsCountSubscriptionHookResult = ReturnType<typeof useSubscribeTestTrialsCountSubscription>;
export type SubscribeTestTrialsCountSubscriptionResult = ApolloReactCommon.SubscriptionResult<Types.SubscribeTestTrialsCountSubscription>;
export const SubscribeTestTrialsDocument = gql`
    subscription SubscribeTestTrials($filter: test_trials_bool_exp, $order_by: [test_trials_order_by!], $offset: Int, $limit: Int) {
  test_trials(where: $filter, order_by: $order_by, offset: $offset, limit: $limit) {
    ...TestTrialPartial
  }
}
    ${TestTrialPartialFragmentDoc}`;

/**
 * __useSubscribeTestTrialsSubscription__
 *
 * To run a query within a React component, call `useSubscribeTestTrialsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeTestTrialsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeTestTrialsSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      order_by: // value for 'order_by'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSubscribeTestTrialsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<Types.SubscribeTestTrialsSubscription, Types.SubscribeTestTrialsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<Types.SubscribeTestTrialsSubscription, Types.SubscribeTestTrialsSubscriptionVariables>(SubscribeTestTrialsDocument, options);
      }
export type SubscribeTestTrialsSubscriptionHookResult = ReturnType<typeof useSubscribeTestTrialsSubscription>;
export type SubscribeTestTrialsSubscriptionResult = ApolloReactCommon.SubscriptionResult<Types.SubscribeTestTrialsSubscription>;
export const GetTestTrialsByAssessmentLinkIdDocument = gql`
    query GetTestTrialsByAssessmentLinkId($filter: test_trials_bool_exp!, $assessmentLinkId: String, $order_by: [test_trials_order_by!], $offset: Int, $limit: Int) {
  test_trials_aggregate(
    where: {_and: [$filter, {assessment_link_id: {_eq: $assessmentLinkId}}]}
    order_by: $order_by
  ) {
    aggregate {
      count
    }
  }
  test_trials(
    where: {_and: [$filter, {assessment_link_id: {_eq: $assessmentLinkId}}]}
    order_by: $order_by
    offset: $offset
    limit: $limit
  ) {
    ...TestTrialPartial
  }
}
    ${TestTrialPartialFragmentDoc}`;

/**
 * __useGetTestTrialsByAssessmentLinkIdQuery__
 *
 * To run a query within a React component, call `useGetTestTrialsByAssessmentLinkIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTestTrialsByAssessmentLinkIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestTrialsByAssessmentLinkIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      assessmentLinkId: // value for 'assessmentLinkId'
 *      order_by: // value for 'order_by'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetTestTrialsByAssessmentLinkIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.GetTestTrialsByAssessmentLinkIdQuery, Types.GetTestTrialsByAssessmentLinkIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetTestTrialsByAssessmentLinkIdQuery, Types.GetTestTrialsByAssessmentLinkIdQueryVariables>(GetTestTrialsByAssessmentLinkIdDocument, options);
      }
export function useGetTestTrialsByAssessmentLinkIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetTestTrialsByAssessmentLinkIdQuery, Types.GetTestTrialsByAssessmentLinkIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetTestTrialsByAssessmentLinkIdQuery, Types.GetTestTrialsByAssessmentLinkIdQueryVariables>(GetTestTrialsByAssessmentLinkIdDocument, options);
        }
export type GetTestTrialsByAssessmentLinkIdQueryHookResult = ReturnType<typeof useGetTestTrialsByAssessmentLinkIdQuery>;
export type GetTestTrialsByAssessmentLinkIdLazyQueryHookResult = ReturnType<typeof useGetTestTrialsByAssessmentLinkIdLazyQuery>;
export type GetTestTrialsByAssessmentLinkIdQueryResult = ApolloReactCommon.QueryResult<Types.GetTestTrialsByAssessmentLinkIdQuery, Types.GetTestTrialsByAssessmentLinkIdQueryVariables>;
export const UpdateTestTrialsDocument = gql`
    mutation UpdateTestTrials($id: Int, $set: test_trials_set_input) {
  update_test_trials(where: {id: {_eq: $id}}, _set: $set) {
    affected_rows
  }
}
    `;
export type UpdateTestTrialsMutationFn = ApolloReactCommon.MutationFunction<Types.UpdateTestTrialsMutation, Types.UpdateTestTrialsMutationVariables>;

/**
 * __useUpdateTestTrialsMutation__
 *
 * To run a mutation, you first call `useUpdateTestTrialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTestTrialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTestTrialsMutation, { data, loading, error }] = useUpdateTestTrialsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateTestTrialsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UpdateTestTrialsMutation, Types.UpdateTestTrialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.UpdateTestTrialsMutation, Types.UpdateTestTrialsMutationVariables>(UpdateTestTrialsDocument, options);
      }
export type UpdateTestTrialsMutationHookResult = ReturnType<typeof useUpdateTestTrialsMutation>;
export type UpdateTestTrialsMutationResult = ApolloReactCommon.MutationResult<Types.UpdateTestTrialsMutation>;
export type UpdateTestTrialsMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UpdateTestTrialsMutation, Types.UpdateTestTrialsMutationVariables>;
export const UpdateTestTrialsByUserIdDocument = gql`
    mutation UpdateTestTrialsByUserId($user_id: Int, $set: test_trials_set_input) {
  update_test_trials(where: {user_id: {_eq: $user_id}}, _set: $set) {
    affected_rows
  }
}
    `;
export type UpdateTestTrialsByUserIdMutationFn = ApolloReactCommon.MutationFunction<Types.UpdateTestTrialsByUserIdMutation, Types.UpdateTestTrialsByUserIdMutationVariables>;

/**
 * __useUpdateTestTrialsByUserIdMutation__
 *
 * To run a mutation, you first call `useUpdateTestTrialsByUserIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTestTrialsByUserIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTestTrialsByUserIdMutation, { data, loading, error }] = useUpdateTestTrialsByUserIdMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateTestTrialsByUserIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UpdateTestTrialsByUserIdMutation, Types.UpdateTestTrialsByUserIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.UpdateTestTrialsByUserIdMutation, Types.UpdateTestTrialsByUserIdMutationVariables>(UpdateTestTrialsByUserIdDocument, options);
      }
export type UpdateTestTrialsByUserIdMutationHookResult = ReturnType<typeof useUpdateTestTrialsByUserIdMutation>;
export type UpdateTestTrialsByUserIdMutationResult = ApolloReactCommon.MutationResult<Types.UpdateTestTrialsByUserIdMutation>;
export type UpdateTestTrialsByUserIdMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UpdateTestTrialsByUserIdMutation, Types.UpdateTestTrialsByUserIdMutationVariables>;
export const GetTestsDocument = gql`
    query GetTests {
  tests(order_by: {name: asc}) {
    ...TestPartial
  }
}
    ${TestPartialFragmentDoc}`;

/**
 * __useGetTestsQuery__
 *
 * To run a query within a React component, call `useGetTestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTestsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GetTestsQuery, Types.GetTestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetTestsQuery, Types.GetTestsQueryVariables>(GetTestsDocument, options);
      }
export function useGetTestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetTestsQuery, Types.GetTestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetTestsQuery, Types.GetTestsQueryVariables>(GetTestsDocument, options);
        }
export type GetTestsQueryHookResult = ReturnType<typeof useGetTestsQuery>;
export type GetTestsLazyQueryHookResult = ReturnType<typeof useGetTestsLazyQuery>;
export type GetTestsQueryResult = ApolloReactCommon.QueryResult<Types.GetTestsQuery, Types.GetTestsQueryVariables>;
export const GetFilterTestDocument = gql`
    query GetFilterTest($filter: tests_bool_exp, $offset: Int, $limit: Int, $order_by: [tests_order_by!]) {
  tests_aggregate(where: $filter, order_by: $order_by) {
    aggregate {
      count
    }
  }
  tests(where: $filter, offset: $offset, limit: $limit, order_by: $order_by) {
    ...TestCatalogPartials
  }
}
    ${TestCatalogPartialsFragmentDoc}`;

/**
 * __useGetFilterTestQuery__
 *
 * To run a query within a React component, call `useGetFilterTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterTestQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetFilterTestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GetFilterTestQuery, Types.GetFilterTestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetFilterTestQuery, Types.GetFilterTestQueryVariables>(GetFilterTestDocument, options);
      }
export function useGetFilterTestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetFilterTestQuery, Types.GetFilterTestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetFilterTestQuery, Types.GetFilterTestQueryVariables>(GetFilterTestDocument, options);
        }
export type GetFilterTestQueryHookResult = ReturnType<typeof useGetFilterTestQuery>;
export type GetFilterTestLazyQueryHookResult = ReturnType<typeof useGetFilterTestLazyQuery>;
export type GetFilterTestQueryResult = ApolloReactCommon.QueryResult<Types.GetFilterTestQuery, Types.GetFilterTestQueryVariables>;
export const GetPrivateTestsDocument = gql`
    query GetPrivateTests($company_id: Int!) {
  tests(
    where: {_or: [{public: {_eq: true}}, {company_tests: {company_id: {_eq: $company_id}}}], status: {_eq: "ready"}}
    order_by: {name: asc}
  ) {
    ...TestPrivatePartial
  }
}
    ${TestPrivatePartialFragmentDoc}`;

/**
 * __useGetPrivateTestsQuery__
 *
 * To run a query within a React component, call `useGetPrivateTestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivateTestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivateTestsQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetPrivateTestsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.GetPrivateTestsQuery, Types.GetPrivateTestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetPrivateTestsQuery, Types.GetPrivateTestsQueryVariables>(GetPrivateTestsDocument, options);
      }
export function useGetPrivateTestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetPrivateTestsQuery, Types.GetPrivateTestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetPrivateTestsQuery, Types.GetPrivateTestsQueryVariables>(GetPrivateTestsDocument, options);
        }
export type GetPrivateTestsQueryHookResult = ReturnType<typeof useGetPrivateTestsQuery>;
export type GetPrivateTestsLazyQueryHookResult = ReturnType<typeof useGetPrivateTestsLazyQuery>;
export type GetPrivateTestsQueryResult = ApolloReactCommon.QueryResult<Types.GetPrivateTestsQuery, Types.GetPrivateTestsQueryVariables>;
export const GetPrivateTestByIdDocument = gql`
    query GetPrivateTestById($brokee_id: Int) {
  tests(where: {brokee_id: {_eq: $brokee_id}}) {
    ...TestPrivatePartial
  }
}
    ${TestPrivatePartialFragmentDoc}`;

/**
 * __useGetPrivateTestByIdQuery__
 *
 * To run a query within a React component, call `useGetPrivateTestByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivateTestByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivateTestByIdQuery({
 *   variables: {
 *      brokee_id: // value for 'brokee_id'
 *   },
 * });
 */
export function useGetPrivateTestByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GetPrivateTestByIdQuery, Types.GetPrivateTestByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetPrivateTestByIdQuery, Types.GetPrivateTestByIdQueryVariables>(GetPrivateTestByIdDocument, options);
      }
export function useGetPrivateTestByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetPrivateTestByIdQuery, Types.GetPrivateTestByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetPrivateTestByIdQuery, Types.GetPrivateTestByIdQueryVariables>(GetPrivateTestByIdDocument, options);
        }
export type GetPrivateTestByIdQueryHookResult = ReturnType<typeof useGetPrivateTestByIdQuery>;
export type GetPrivateTestByIdLazyQueryHookResult = ReturnType<typeof useGetPrivateTestByIdLazyQuery>;
export type GetPrivateTestByIdQueryResult = ApolloReactCommon.QueryResult<Types.GetPrivateTestByIdQuery, Types.GetPrivateTestByIdQueryVariables>;
export const GetPersonPrivateTestByIdDocument = gql`
    query GetPersonPrivateTestById($brokee_id: Int) {
  tests(where: {brokee_id: {_eq: $brokee_id}}) {
    name
    description_human
    description_long
    arch_diagram_image
    requires_payment
    test_type {
      ...test_typesFragment
    }
    test_category {
      ...test_categoriesFragment
    }
    test_difficulty {
      ...test_difficultiesFragment
    }
    test_tech_roles(order_by: {tech_role: {name: asc}}) {
      ...test_tech_rolesFragment
    }
    test_tech_services(order_by: {tech_service: {name: asc}}) {
      ...test_tech_servicesFragment
    }
    test_stat {
      average_passed_time
      pass_rate
    }
    test_tech_services_resources(order_by: {tech_service: {name: asc}}) {
      ...test_tech_services_resourcesFragment
    }
  }
}
    ${Test_TypesFragmentFragmentDoc}
${Test_CategoriesFragmentFragmentDoc}
${Test_DifficultiesFragmentFragmentDoc}
${Test_Tech_RolesFragmentFragmentDoc}
${Test_Tech_ServicesFragmentFragmentDoc}
${Test_Tech_Services_ResourcesFragmentFragmentDoc}`;

/**
 * __useGetPersonPrivateTestByIdQuery__
 *
 * To run a query within a React component, call `useGetPersonPrivateTestByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonPrivateTestByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonPrivateTestByIdQuery({
 *   variables: {
 *      brokee_id: // value for 'brokee_id'
 *   },
 * });
 */
export function useGetPersonPrivateTestByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GetPersonPrivateTestByIdQuery, Types.GetPersonPrivateTestByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetPersonPrivateTestByIdQuery, Types.GetPersonPrivateTestByIdQueryVariables>(GetPersonPrivateTestByIdDocument, options);
      }
export function useGetPersonPrivateTestByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetPersonPrivateTestByIdQuery, Types.GetPersonPrivateTestByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetPersonPrivateTestByIdQuery, Types.GetPersonPrivateTestByIdQueryVariables>(GetPersonPrivateTestByIdDocument, options);
        }
export type GetPersonPrivateTestByIdQueryHookResult = ReturnType<typeof useGetPersonPrivateTestByIdQuery>;
export type GetPersonPrivateTestByIdLazyQueryHookResult = ReturnType<typeof useGetPersonPrivateTestByIdLazyQuery>;
export type GetPersonPrivateTestByIdQueryResult = ApolloReactCommon.QueryResult<Types.GetPersonPrivateTestByIdQuery, Types.GetPersonPrivateTestByIdQueryVariables>;
export const GetTestCatalogueFiltersDocument = gql`
    query GetTestCatalogueFilters {
  test_types {
    id
    type
  }
  test_difficulties {
    id
    level
  }
  test_categories {
    id
    name
  }
  tech_roles {
    id
    name
  }
  tech_services {
    id
    name
  }
  tech_service_categories {
    id
    name
  }
}
    `;

/**
 * __useGetTestCatalogueFiltersQuery__
 *
 * To run a query within a React component, call `useGetTestCatalogueFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTestCatalogueFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestCatalogueFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTestCatalogueFiltersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GetTestCatalogueFiltersQuery, Types.GetTestCatalogueFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetTestCatalogueFiltersQuery, Types.GetTestCatalogueFiltersQueryVariables>(GetTestCatalogueFiltersDocument, options);
      }
export function useGetTestCatalogueFiltersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetTestCatalogueFiltersQuery, Types.GetTestCatalogueFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetTestCatalogueFiltersQuery, Types.GetTestCatalogueFiltersQueryVariables>(GetTestCatalogueFiltersDocument, options);
        }
export type GetTestCatalogueFiltersQueryHookResult = ReturnType<typeof useGetTestCatalogueFiltersQuery>;
export type GetTestCatalogueFiltersLazyQueryHookResult = ReturnType<typeof useGetTestCatalogueFiltersLazyQuery>;
export type GetTestCatalogueFiltersQueryResult = ApolloReactCommon.QueryResult<Types.GetTestCatalogueFiltersQuery, Types.GetTestCatalogueFiltersQueryVariables>;
export const GetPaymentRequireDocument = gql`
    query GetPaymentRequire($brokee_id: Int) {
  tests(where: {brokee_id: {_eq: $brokee_id}}) {
    brokee_id
    requires_payment
  }
}
    `;

/**
 * __useGetPaymentRequireQuery__
 *
 * To run a query within a React component, call `useGetPaymentRequireQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentRequireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentRequireQuery({
 *   variables: {
 *      brokee_id: // value for 'brokee_id'
 *   },
 * });
 */
export function useGetPaymentRequireQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GetPaymentRequireQuery, Types.GetPaymentRequireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetPaymentRequireQuery, Types.GetPaymentRequireQueryVariables>(GetPaymentRequireDocument, options);
      }
export function useGetPaymentRequireLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetPaymentRequireQuery, Types.GetPaymentRequireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetPaymentRequireQuery, Types.GetPaymentRequireQueryVariables>(GetPaymentRequireDocument, options);
        }
export type GetPaymentRequireQueryHookResult = ReturnType<typeof useGetPaymentRequireQuery>;
export type GetPaymentRequireLazyQueryHookResult = ReturnType<typeof useGetPaymentRequireLazyQuery>;
export type GetPaymentRequireQueryResult = ApolloReactCommon.QueryResult<Types.GetPaymentRequireQuery, Types.GetPaymentRequireQueryVariables>;
export const GetTrailSubmissionResultsDocument = gql`
    subscription GetTrailSubmissionResults($trial_id: Int!) {
  test_trials_by_pk(id: $trial_id) {
    time_taken_seconds
    passed
    total_tasks
    completed_tasks
    requester_id
    user_id
    user_test_stat {
      ...UserTestStatsFragment
    }
    candidate {
      email
      name
      id
    }
    company {
      name
    }
    test {
      badge {
        ...badgesFragment
      }
      badge_awards {
        ...badge_awardsFragment
      }
      brokee_id
      name
      test_category {
        ...test_categoriesFragment
      }
      test_tech_services_resources(order_by: {tech_service: {name: asc}}) {
        ...test_tech_services_resourcesFragment
      }
      test_stat {
        ...test_statsFragment
      }
    }
  }
}
    ${UserTestStatsFragmentFragmentDoc}
${BadgesFragmentFragmentDoc}
${Badge_AwardsFragmentFragmentDoc}
${Test_CategoriesFragmentFragmentDoc}
${Test_Tech_Services_ResourcesFragmentFragmentDoc}
${Test_StatsFragmentFragmentDoc}`;

/**
 * __useGetTrailSubmissionResultsSubscription__
 *
 * To run a query within a React component, call `useGetTrailSubmissionResultsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetTrailSubmissionResultsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrailSubmissionResultsSubscription({
 *   variables: {
 *      trial_id: // value for 'trial_id'
 *   },
 * });
 */
export function useGetTrailSubmissionResultsSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<Types.GetTrailSubmissionResultsSubscription, Types.GetTrailSubmissionResultsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<Types.GetTrailSubmissionResultsSubscription, Types.GetTrailSubmissionResultsSubscriptionVariables>(GetTrailSubmissionResultsDocument, options);
      }
export type GetTrailSubmissionResultsSubscriptionHookResult = ReturnType<typeof useGetTrailSubmissionResultsSubscription>;
export type GetTrailSubmissionResultsSubscriptionResult = ApolloReactCommon.SubscriptionResult<Types.GetTrailSubmissionResultsSubscription>;
export const TopRunnigTestsPerAccountDocument = gql`
    query TopRunnigTestsPerAccount($limit: Int!) {
  tests(limit: $limit, order_by: {test_trials_aggregate: {count: desc}}) {
    brokee_id
    name
    featured_image
    description_short
    requires_payment
    total_test_trials_aggregate: test_trials_aggregate {
      ...test_trials_aggregateCountFragment
    }
    passed_test_trials_aggregate: test_trials_aggregate(
      where: {passed: {_eq: true}}
    ) {
      ...test_trials_aggregateFragment
    }
  }
}
    ${Test_Trials_AggregateCountFragmentFragmentDoc}
${Test_Trials_AggregateFragmentFragmentDoc}`;

/**
 * __useTopRunnigTestsPerAccountQuery__
 *
 * To run a query within a React component, call `useTopRunnigTestsPerAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopRunnigTestsPerAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopRunnigTestsPerAccountQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTopRunnigTestsPerAccountQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.TopRunnigTestsPerAccountQuery, Types.TopRunnigTestsPerAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.TopRunnigTestsPerAccountQuery, Types.TopRunnigTestsPerAccountQueryVariables>(TopRunnigTestsPerAccountDocument, options);
      }
export function useTopRunnigTestsPerAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.TopRunnigTestsPerAccountQuery, Types.TopRunnigTestsPerAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.TopRunnigTestsPerAccountQuery, Types.TopRunnigTestsPerAccountQueryVariables>(TopRunnigTestsPerAccountDocument, options);
        }
export type TopRunnigTestsPerAccountQueryHookResult = ReturnType<typeof useTopRunnigTestsPerAccountQuery>;
export type TopRunnigTestsPerAccountLazyQueryHookResult = ReturnType<typeof useTopRunnigTestsPerAccountLazyQuery>;
export type TopRunnigTestsPerAccountQueryResult = ApolloReactCommon.QueryResult<Types.TopRunnigTestsPerAccountQuery, Types.TopRunnigTestsPerAccountQueryVariables>;
export const FailedTestsPerAccountDocument = gql`
    query FailedTestsPerAccount {
  tests(order_by: {test_trials_aggregate: {count: desc}}) {
    brokee_id
    name
    featured_image
    description_short
    requires_payment
    total_test_trials_aggregate: test_trials_aggregate {
      ...test_trials_aggregateCountFragment
    }
    failed_test_trials_aggregate: test_trials_aggregate(
      where: {passed: {_eq: false}}
    ) {
      ...test_trials_aggregateFragment
    }
  }
}
    ${Test_Trials_AggregateCountFragmentFragmentDoc}
${Test_Trials_AggregateFragmentFragmentDoc}`;

/**
 * __useFailedTestsPerAccountQuery__
 *
 * To run a query within a React component, call `useFailedTestsPerAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFailedTestsPerAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFailedTestsPerAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useFailedTestsPerAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.FailedTestsPerAccountQuery, Types.FailedTestsPerAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.FailedTestsPerAccountQuery, Types.FailedTestsPerAccountQueryVariables>(FailedTestsPerAccountDocument, options);
      }
export function useFailedTestsPerAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.FailedTestsPerAccountQuery, Types.FailedTestsPerAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.FailedTestsPerAccountQuery, Types.FailedTestsPerAccountQueryVariables>(FailedTestsPerAccountDocument, options);
        }
export type FailedTestsPerAccountQueryHookResult = ReturnType<typeof useFailedTestsPerAccountQuery>;
export type FailedTestsPerAccountLazyQueryHookResult = ReturnType<typeof useFailedTestsPerAccountLazyQuery>;
export type FailedTestsPerAccountQueryResult = ApolloReactCommon.QueryResult<Types.FailedTestsPerAccountQuery, Types.FailedTestsPerAccountQueryVariables>;
export const GetTransactionsDocument = gql`
    query GetTransactions($offset: Int, $limit: Int, $company_id: Int) {
  transactions_aggregate(
    where: {_and: {company_id: {_eq: $company_id}, transaction_type: {_eq: "invoice"}}}
  ) {
    aggregate {
      count
    }
  }
  transactions(
    where: {_and: {company_id: {_eq: $company_id}, transaction_type: {_eq: "invoice"}}}
    order_by: {created_at: desc}
    offset: $offset
    limit: $limit
  ) {
    ...TransactionsPartial
  }
}
    ${TransactionsPartialFragmentDoc}`;

/**
 * __useGetTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetTransactionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GetTransactionsQuery, Types.GetTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetTransactionsQuery, Types.GetTransactionsQueryVariables>(GetTransactionsDocument, options);
      }
export function useGetTransactionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetTransactionsQuery, Types.GetTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetTransactionsQuery, Types.GetTransactionsQueryVariables>(GetTransactionsDocument, options);
        }
export type GetTransactionsQueryHookResult = ReturnType<typeof useGetTransactionsQuery>;
export type GetTransactionsLazyQueryHookResult = ReturnType<typeof useGetTransactionsLazyQuery>;
export type GetTransactionsQueryResult = ApolloReactCommon.QueryResult<Types.GetTransactionsQuery, Types.GetTransactionsQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers($company_id: Int!, $company_role_id: Int) {
  users(
    where: {_and: {company_id: {_eq: $company_id}, company_role_id: {_eq: $company_role_id}, deleted_at: {_is_null: true}}}
  ) {
    ...UsersPartial
    company_role {
      ...companyRolePartial
    }
  }
}
    ${UsersPartialFragmentDoc}
${CompanyRolePartialFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      company_role_id: // value for 'company_role_id'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.GetUsersQuery, Types.GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetUsersQuery, Types.GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetUsersQuery, Types.GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetUsersQuery, Types.GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = ApolloReactCommon.QueryResult<Types.GetUsersQuery, Types.GetUsersQueryVariables>;
export const UpdateUserByUserIdDocument = gql`
    mutation UpdateUserByUserId($user_id: Int, $set: users_set_input) {
  update_users(where: {id: {_eq: $user_id}}, _set: $set) {
    affected_rows
  }
}
    `;
export type UpdateUserByUserIdMutationFn = ApolloReactCommon.MutationFunction<Types.UpdateUserByUserIdMutation, Types.UpdateUserByUserIdMutationVariables>;

/**
 * __useUpdateUserByUserIdMutation__
 *
 * To run a mutation, you first call `useUpdateUserByUserIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserByUserIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserByUserIdMutation, { data, loading, error }] = useUpdateUserByUserIdMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateUserByUserIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UpdateUserByUserIdMutation, Types.UpdateUserByUserIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.UpdateUserByUserIdMutation, Types.UpdateUserByUserIdMutationVariables>(UpdateUserByUserIdDocument, options);
      }
export type UpdateUserByUserIdMutationHookResult = ReturnType<typeof useUpdateUserByUserIdMutation>;
export type UpdateUserByUserIdMutationResult = ApolloReactCommon.MutationResult<Types.UpdateUserByUserIdMutation>;
export type UpdateUserByUserIdMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UpdateUserByUserIdMutation, Types.UpdateUserByUserIdMutationVariables>;