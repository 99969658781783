import { PROBLEMS_THRESHOLD_PERCENTAGE } from '../constants/common';

export const calculateCompletionPercentage = (
  completedTasks: number,
  totalTasks: number
) => {
  if (totalTasks === 0) return 0;
  const percentage = (completedTasks / totalTasks) * 100;
  return Math.round(percentage);
};

export const getCompletionPercentageColor = (percentage: number) => {
  if (percentage >= 70) return 'success.main';
  if (percentage >= 35) return 'warning.main';
  return 'error.main';
};

export function calculateProblemsThreshold(defaultValue: number): {
  maxThreshold: number;
  minThreshold: number;
} {
  const calculatedIncrement = defaultValue * PROBLEMS_THRESHOLD_PERCENTAGE;
  const minThreshold = Math.floor(defaultValue - calculatedIncrement);
  const maxThreshold = Math.ceil(defaultValue + calculatedIncrement);

  return { minThreshold, maxThreshold };
}

export function convertSecondsToMinutes(seconds: number): number {
  const minutes = Math.floor(seconds / 60);

  return minutes;
}

export const getPercentageDifference = (current: number, average: number) => {
  if (average === 0) return 0;
  const difference = Math.abs((current - average) / average) * 100;
  return parseFloat(difference.toFixed(2));
};

export function formatRegularRankingPositionDescription(
  ranking_position: number,
  total_passed: number
): string {
  if (total_passed <= 1) return 'You are only one who completed the test';
  const description = `You are better than ${(
    (1 - ranking_position / total_passed) *
    100
  ).toFixed(2)}% of all candidates`;
  return description;
}

export function formatCompanyRankingPositionDescription(
  ranking_position: number,
  total_passed: number
): string {
  if (total_passed <= 1)
    return 'This is only one candidate who completed the test';

  const description = `This candidate is better than ${(
    (1 - ranking_position / total_passed) *
    100
  ).toFixed(2)}% of all candidates`;

  return description;
}

export function sanitizeTestName(testName: string) {
  return testName
    .replace(/[:/\\*?"<>|]/g, '_') // Replace special characters with _
    .replace(/\s+/g, '_') // Replace multiple spaces with a single _
    .replace(/__+/g, '_'); // Replace multiple underscores with a single _
}

export const formatToReadableDateTime = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  };

  const formattedDate = date.toLocaleDateString('en-GB', options);

  return formattedDate;
};

export const isDateExpired = (expirationDate: string): boolean => {
  const now = new Date();
  const expiration = new Date(expirationDate);

  return now > expiration;
};
