import { useAuth0 } from '@auth0/auth0-react';
import Bugsnag, { NotifiableError } from '@bugsnag/js';
import arrowBackFill from '@iconify/icons-eva/arrow-back-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import copyOutline from '@iconify/icons-eva/copy-outline';
import downloadFill from '@iconify/icons-eva/download-fill';
import linkedinIcon from '@iconify/icons-eva/linkedin-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import syncFill from '@iconify/icons-eva/sync-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import { Icon } from '@iconify/react';
import {
  Alert,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Link as LinkStyle,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useGetTestTrialByIdQuery } from '../../../@generated/facadeClient';
import {
  TestTrialPartialFragment,
  UserTestTrialPartialFragment,
} from '../../../@generated/types';
import AsciinemaPlayer from '../../../components/AsciinemaPlayer/AsciinemaPlayer';
import { MoreMenuWithButton } from '../../../components/MoreMenuWithButton';
import { SubscriptionAlert } from '../../../components/SubscriptionAlert';
import { TabPanel } from '../../../components/Tabpanel/TabPanel';
import { EEnvironmentType } from '../../../constants/assessment';
import { ESubscriptionFeaturesList } from '../../../constants/features';
import { candidateWebsite } from '../../../constants/links';
import { useBilling } from '../../../hooks/useBilling';
import { isDateExpired, sanitizeTestName } from '../../../utils/common';
import AddAnotherTestDialog from '../AddAnotherTestDialog';
import DeleteTestDialog from '../DeleteTestDialog';
import ResetTestDialog from '../ResetTestDialog';
import useCandidateActions from '../hooks/useCandidatesActions';
import {
  IUserCommandHistory,
  downloadFile,
  downloadReport,
  generatePublicRecordings,
  getDateTime,
  getTestStatus,
  getTestTimeTaken,
  getUserCommandHistory,
} from '../utils';
import { CandidatesPageDetailsBody } from './CandidatesPageDetailsBody';

export default function CandidatesPageDetails() {
  const params = useParams<{ id: string; testId: string }>();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [severity, setSeverity] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState('');

  const [shareableRecLink, setShareableRecLink] = useState<string | null>(null);
  const [expiredRecLinkMsg, setExpiredRecLinkMsg] = useState<string | null>(
    null
  );

  const [generatingRec, setGeneratingRec] = useState(false);
  const [recLinkExpired, setRecLinkExpired] = useState(false);

  const [recordUrls, setRecordUrl] = useState<IUserCommandHistory | null>(null);
  const [recordError, setRecordError] = useState<boolean | null>(null);
  const [tabValue, setTabValue] = useState('0');
  const [deleteTestTrial, setDeleteTestTrial] =
    useState<TestTrialPartialFragment | null>(null);
  const [resetTestTrail, setResetTestTrail] =
    useState<TestTrialPartialFragment | null>(null);
  const [showAddAnotherTestDialog, setShowAddAnotherTestDialog] =
    useState(false);
  const { canAccessProFeatures, canAccessFreeFeatures } = useBilling();

  const { data, loading } = useGetTestTrialByIdQuery({
    variables: { id: Number(params.testId) },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const testData = data?.test_trials ? data.test_trials[0] : null;
  const candidate = testData?.candidate;
  const recordings = testData?.public_recordings;
  const { linkedin, picture, email, logged_at } = candidate || {};

  const { resendInvite, handleCopyInviteUrl } = useCandidateActions({
    onError: handleError,
    onSuccess: handleSuccess,
  });

  const onDownloadCommandHistory = useCallback(
    async function onDownloadCommandHistory(
      testData: UserTestTrialPartialFragment
    ) {
      try {
        const token = await getAccessTokenSilently();
        const download_url = await getUserCommandHistory(
          token,
          testData.candidate.userhash,
          testData.test.brokee_id,
          true,
          testData.started_at,
          testData.submitted_at
        );

        setRecordUrl(download_url);
      } catch (error) {
        setRecordError(true);
        Bugsnag.notify(error as NotifiableError);
      }
    },
    [getAccessTokenSilently]
  );

  useEffect(() => {
    if (testData?.submitted_at) {
      onDownloadCommandHistory(testData);
    }
    if (recordings && recordings?.length !== 0) {
      setShareableRecLink(`${candidateWebsite}recordings/${recordings[0].uid}`);
      setExpiredRecLinkMsg(`Expires on ${recordings[0].expires_at}`);
      setRecLinkExpired(isDateExpired(recordings[0].expires_at));
    }
    return () => {
      setRecordUrl(null);
    };
  }, [testData, recordings, onDownloadCommandHistory]);

  function handleDeleteDialogClose() {
    setDeleteTestTrial(null);
  }

  function handleDelete(testTrial: TestTrialPartialFragment) {
    setDeleteTestTrial(testTrial);
  }

  function handleDeleteDialogSuccess(message: string) {
    setDeleteTestTrial(null);
    handleSuccess(message);
    navigate(-1);
  }

  function handleSuccess(message: string) {
    setShowSnackbar(true);
    setSeverity('success');
    setMessage(message);
  }

  function handleError(error: string) {
    setShowSnackbar(true);
    setSeverity('error');
    setMessage(error);
    setDeleteTestTrial(null);
  }

  function handleSnackbarClose() {
    setShowSnackbar(false);
  }

  function onGettingCommandHistoryError(message: string) {
    setShowSnackbar(true);
    setSeverity('error');
    setMessage(message);
  }

  function onAddAnotherTest() {
    setShowAddAnotherTestDialog(true);
  }

  function handleAddAnotherTestDialogSuccess() {
    setShowAddAnotherTestDialog(false);
    handleSuccess('Test added successfully.');
  }

  function handleGoToCandidates(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    navigate(-1);
  }

  function onTestReset(testTrial: TestTrialPartialFragment) {
    setResetTestTrail(testTrial);
  }

  const handleResetTestDialogSuccess = (message: string) => {
    setResetTestTrail(null);
    handleSuccess(message);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handleCopyLink = () => {
    if (shareableRecLink) {
      navigator.clipboard.writeText(shareableRecLink);

      setShowSnackbar(true);
      setSeverity('success');
      setMessage('Public link copied');
    }
  };

  if (loading)
    return (
      <Box
        sx={{
          h: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );

  if (!testData) {
    return <Navigate to="/company/candidates" />;
  }
  const globalTestMetrics = testData.test.test_stat;

  const passStatus = getTestStatus(testData.passed);
  const timeTaken =
    testData.time_taken_seconds === 0
      ? 'Submitted in under 1 minute'
      : getTestTimeTaken(testData.time_taken_seconds);

  const downloadTestReport = async () => {
    try {
      const token = await getAccessTokenSilently();

      const completionDetails = JSON.parse(testData.completion_details || '{}');

      const reportBody = {
        ai_summary: testData.ai_summary || '',
        candidate_email: candidate?.email || 'N/A',
        candidate_name: candidate?.name || 'N/A',
        linkedin: candidate?.linkedin || '',
        invitation_date: testData.invited_at,
        completed_tasks: testData.completed_tasks || 0,
        completion_score: testData.completion_score || 0,
        completion_details: completionDetails,
        status: passStatus,
        submission_date: testData.submitted_at,
        test_name: testData.test.name,
        test_description: testData.test.description_human || '',
        time_taken: timeTaken || 'N/A',
        total_tasks: testData.total_tasks || 0,
        source_url: `${candidateWebsite}company/candidates/${params.id}/tests/${params.testId}`,
      };

      const report = await downloadReport(token, reportBody);
      const filename = `${sanitizeTestName(reportBody.test_name)}_${
        reportBody.candidate_email
      }_Report.pdf`;

      const blob = new Blob([report.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      downloadFile(url, filename);
    } catch (error) {
      const errorMessage = (error as Error).message;
      setShowSnackbar(true);
      setSeverity('error');
      setMessage('Something went wrong. ' + errorMessage);
      Bugsnag.notify(error as NotifiableError);
    }
  };

  const generatedPublicRecordings = async () => {
    try {
      setGeneratingRec(true);
      const token = await getAccessTokenSilently();

      const recordData = {
        trial_id: testData.id,
        test_id: testData.test.brokee_id,
        submitted_at: testData.submitted_at,
        started_at: testData.started_at,
        userhash: testData.candidate.userhash,
      };

      const response = await generatePublicRecordings(token, recordData);
      const link = `${candidateWebsite}recordings/${response.uid}`;

      setShareableRecLink(link);
      setExpiredRecLinkMsg(`Expires on ${response.expires_at}`);
      setGeneratingRec(false);
      setRecLinkExpired(false);

      navigator.clipboard.writeText(link);

      setShowSnackbar(true);
      setSeverity('success');
      setMessage('Public link successfully generated and copied to clipboard.');
    } catch (error) {
      const errorMessage = (error as Error).message;
      setGeneratingRec(false);
      setShowSnackbar(true);
      setSeverity('error');
      setMessage('Something went wrong. ' + errorMessage);
      Bugsnag.notify(error as NotifiableError);
    }
  };

  return (
    <Stack sx={{ pt: canAccessProFeatures() ? '2rem' : 0 }} spacing={6}>
      {!canAccessProFeatures() && (
        <Box sx={{ width: '100%' }}>
          <SubscriptionAlert
            features={ESubscriptionFeaturesList.CANDIDATE_RESULT_DETAILS_PAGE}
          />
        </Box>
      )}
      <LinkStyle
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          textDecoration: 'none',
          color: 'text.primary',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
        component={Link}
        to={'/company/candidates/'}
        onClick={handleGoToCandidates}
      >
        <Icon
          icon={arrowBackFill}
          width={24}
          height={24}
          style={{ marginRight: '.5rem' }}
        />
        <Typography variant={'subtitle2'} fontWeight={500}>
          Back to Candidates
        </Typography>
      </LinkStyle>
      <Stack
        direction={{ md: 'row' }}
        spacing={{ xs: '2rem', md: '2rem' }}
        sx={{
          justifyContent: 'space-between',
          mt: '2rem',
        }}
      >
        <Stack direction={'row'} sx={{ alignItems: 'center' }}>
          <Avatar
            alt={email}
            src={picture || ''}
            sx={{
              width: '4rem',
              height: '4rem',
            }}
          />
          <Stack direction={'column'} sx={{ ml: '1rem' }}>
            <Typography
              variant={'h4'}
              sx={{
                fontSize: '1rem',
                '@media (min-width:600px)': {
                  fontSize: '1.6667rem',
                },
                '@media (min-width:900px)': {
                  fontSize: '1.875rem',
                },
                '@media (min-width:1200px)': {
                  fontSize: '2.0833rem',
                },
              }}
            >
              {email}
              {linkedin && (
                <Link
                  to={linkedin ?? ''}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    marginTop: '0.25rem',
                    marginLeft: '0.25rem',
                    filter: 'grayscale(1)',
                  }}
                >
                  <Icon icon={linkedinIcon} width="16" height="16" />
                </Link>
              )}
            </Typography>
            <Stack sx={{ mt: '8px' }} direction={'row'}>
              <Typography variant={'subtitle2'} fontWeight={500}>
                Invited at:
              </Typography>
              <Chip
                label={getDateTime(testData.invited_at)}
                variant="filled"
                size="small"
                sx={{ ml: '8px', mr: '8px' }}
              />
              <Typography variant={'subtitle2'} fontWeight={500}>
                Last login:
              </Typography>
              <Chip
                label={getDateTime(logged_at)}
                variant="filled"
                size="small"
                sx={{ ml: '8px' }}
              />
            </Stack>
          </Stack>
        </Stack>
        <Box>
          <MoreMenuWithButton
            buttonText={'Actions'}
            buttonIcon={arrowIosDownwardFill}
            menuItems={[
              {
                icon: plusFill,
                text: 'Add another test',
                disabled: !canAccessFreeFeatures(),
                onClick: () => {
                  onAddAnotherTest();
                },
              },
              {
                icon: copyOutline,
                text: 'Copy invite URL',
                disabled: !canAccessFreeFeatures(),
                onClick: () => {
                  handleCopyInviteUrl(testData);
                },
              },
              {
                icon: refreshFill,
                text: 'Resend invite',
                disabled: !canAccessFreeFeatures(),
                onClick: () => {
                  resendInvite(testData);
                },
              },

              ...(typeof testData.passed === 'boolean'
                ? [
                    {
                      icon: syncFill,
                      text: 'Reset test result',
                      disabled: !canAccessFreeFeatures(),
                      onClick: () => {
                        onTestReset(testData);
                      },
                    },
                  ]
                : []),
              ...(typeof testData.submitted_at === 'string'
                ? [
                    {
                      icon: downloadFill,
                      text: 'Download Report',
                      disabled: !canAccessFreeFeatures(),
                      onClick: () => {
                        downloadTestReport();
                      },
                    },
                  ]
                : []),
              {
                icon: trash2Fill,
                text: 'Delete test',
                onClick: () => {
                  handleDelete(testData);
                },
              },
            ]}
          />
        </Box>
      </Stack>
      {!!deleteTestTrial && (
        <DeleteTestDialog
          testTrial={deleteTestTrial}
          onClose={handleDeleteDialogClose}
          onSuccess={handleDeleteDialogSuccess}
          onError={handleError}
        />
      )}
      {showAddAnotherTestDialog && testData && (
        <AddAnotherTestDialog
          testTrial={testData}
          handleClose={() => setShowAddAnotherTestDialog(false)}
          onSuccess={handleAddAnotherTestDialogSuccess}
          onError={handleError}
        />
      )}
      {!!resetTestTrail && (
        <ResetTestDialog
          testTrial={resetTestTrail}
          onClose={() => setResetTestTrail(null)}
          onSuccess={handleResetTestDialogSuccess}
          onError={handleError}
        />
      )}
      {showSnackbar && (
        <Snackbar
          open
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert
            variant="filled"
            onClose={handleSnackbarClose}
            severity={severity}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      <CandidatesPageDetailsBody
        globalTestMetrics={globalTestMetrics || {}}
        testTrial={testData}
        onGettingCommandHistoryError={onGettingCommandHistoryError}
      />

      {testData.test?.test_type?.type !== EEnvironmentType.CONSOLE &&
        testData.submitted_at &&
        (recordUrls || recordError) && (
          <Card elevation={3}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              padding={0}
            >
              <CardHeader title={'Replay candidate activity'} />
              {!recordError && (
                <ButtonGroup
                  variant="contained"
                  size="medium"
                  sx={{ margin: 2 }}
                >
                  <Button
                    id="recordings-create-new-link"
                    color="primary"
                    onClick={generatedPublicRecordings}
                    disabled={generatingRec}
                  >
                    {generatingRec ? (
                      <>
                        <CircularProgress
                          size={20}
                          thickness={1}
                          style={{ marginRight: 10 }}
                        />
                        Generating Link
                      </>
                    ) : (
                      'Share Recording'
                    )}
                  </Button>
                  <Tooltip
                    title={
                      recLinkExpired ? 'This link expired' : expiredRecLinkMsg
                    }
                    arrow
                    placement={'top-start'}
                  >
                    <span>
                      <Button
                        id="recordings-copy-link"
                        color="primary"
                        onClick={handleCopyLink}
                        disabled={
                          !shareableRecLink || generatingRec || recLinkExpired
                        }
                      >
                        Copy
                      </Button>
                    </span>
                  </Tooltip>
                </ButtonGroup>
              )}
            </Box>
            {!recordError && (
              <Box p={2}>
                {recordUrls?.files.length !== 1 && (
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="lab API tabs example"
                  >
                    {recordUrls?.files.map((_, index) => {
                      return (
                        <Tab label={`Tab ${index + 1}`} value={`${index}`} />
                      );
                    })}
                  </Tabs>
                )}
                {recordUrls?.files.map((record, index) => {
                  return (
                    <TabPanel
                      value={tabValue}
                      index={`${index}`}
                      sx={{
                        pt: 0.5,
                      }}
                    >
                      <AsciinemaPlayer
                        src={record.download_url}
                        preload={true}
                        terminalFontSize="medium"
                        fit="both"
                        // rows="24"
                        // cols="80"
                        poster="npt:10:00"
                      />
                    </TabPanel>
                  );
                })}
              </Box>
            )}
            {recordError && (
              <Box p={2}>
                <Typography variant="subtitle2">
                  History recording is not available for older test attempts,
                  and for tests that assess skills with Cloud providers. Please
                  note that this feature may also be unavailable in cases of
                  candidate inactivity. If you see this message for recent
                  tests, please contact us via chat or at info@brokee.io for
                  assistance.
                </Typography>
              </Box>
            )}
          </Card>
        )}
    </Stack>
  );
}
